jQuery(document).ready(function($) {
    if($('body').hasClass('register')) {
        //var creator_wrapper = $('.profile-creator-wrapper');
        //var profile_trigger = $("input#want_profile");
        //var progress_bar = $('.profile-creator-heading').find('progress');
        //profile_trigger.change(function() {
        //    var want_profile = profile_trigger.is(':checked');
        //
        //    if(want_profile === true) {
        //        open_profile_creator();
        //    } else {
        //        close_profile_creator();
        //    }
        //}).trigger('change');



        //var cC = $('.choose-company');
        //cC.find('select').change(function() {
        //    $('input#coc_no').val($(this).val()).trigger('keyup');
        //});
        //
        //$('input#coc_no').keyup(function(e) {
        //    //console.log('key');
        //    reset_company_fields();
        //    var form = $(this).parents('form');
        //    var v = $(this).val();
        //    if(v.length > 0) {
        //        $.getKVKData(v, function(data) {
        //            cC.addClass('hide');
        //            if(isNaN(v) && data.length > 0) {
        //                cC.removeClass('hide');
        //                cC.find('select').empty();
        //                cC.find('select').append("<option value='0'></option>");
        //                for(var i in data) {
        //                    var row = data[i];
        //                    cC.find('select').append('<option value="'+row.dossiernummer+'">'+row.handelsnaam+' '+row.plaats+'</option>');
        //                }
        //                return;
        //            }
        //            if(data === false) {
        //                form.find('span.error').show();
        //                //cD.parents('.row.box').parent().hide();
        //                return;
        //            }
        //            //cD.parents('.row.box').parent().show();
        //
        //            creator_wrapper.find('input#company_name').val(data.handelsnaam).trigger('keyup');
        //            creator_wrapper.find('input#city').val(data.plaats).trigger('keyup');
        //            creator_wrapper.find('input#postal_code').val(data.postcode).trigger('keyup');
        //            creator_wrapper.find('input#street').val(data.straat).trigger('keyup');
        //            creator_wrapper.find('input#street_no').val(data.huisnummer).trigger('keyup');
        //            creator_wrapper.find('input#street_no_adding').val(data.huisnummertoevoeging).trigger('keyup');
        //            creator_wrapper.find('input[name=coc_no]').val(data.dossiernummer);
        //
        //        });
        //    }
        //}).trigger('keyup');

        //$('button.continue-profile').click(function() {
        //    var current = $(this).parents('.profile-creator-step');
        //    var next = current.next();
        //    var l = current.outerWidth() * next.index();
        //    set_progress(progress_bar, next.index()+1);
        //    creator_wrapper.animate({'scrollLeft':l});
        //});
        //$('button.back-profile').click(function() {
        //    var current = $(this).parents('.profile-creator-step');
        //    var prev = current.prev();
        //    var l = current.outerWidth() * prev.index();
        //    set_progress(progress_bar, prev.index()+1);
        //    creator_wrapper.animate({'scrollLeft':l})
        //});

        //var form = $('form.register_form');
        //form.find('input[type=submit]').click(function(e) {
        //    if($ == undefined)
        //    {
        //        var $ = jQuery.noConflict();
        //    }
        //    if($('select#professions').val() == null) {
        //        $(this).parents('.profile-creator-step#step3').find('button.back-profile').trigger('click');
        //    }
        //});
        //
        //var matching_skills = {};
        //$('select#professions').change(function() {
        //    var data = $(this).val();
        //    $.each(data,function(i, v) {
        //        if(!matching_skills[v]) {
        //            $.iGetModel('Profession','get_attribute',{'attribute':'skills','id':v},function(data){
        //                var selector = $('select#skills');
        //                //selector.empty();
        //                for(var key in data) {
        //                    if(selector.find('option[value='+data[key]['id']+']').length == false) {
        //                        selector.append('<option value="'+data[key]['id']+'" selected="selected">'+data[key]['name']+'</option>');
        //                    }
        //                }
        //                selector.trigger('change');
        //            });
        //        }
        //    });
        //});

    }
    if($('body').hasClass('register_follow_up')) {
        $('input[name=account_type]').change(function() {
            var other_id = $('input[name=account_type]').not('[value='+$(this).val()+']').attr('value');
            $('.'+other_id+'-form-wrapper').hide();
            $('.'+$(this).val()+'-form-wrapper').show();
            $('.'+$(this).val()+'-form-wrapper').find(':input:not([data-ajax-autocomplete-url]):first').trigger('keyup');
            $('body, html').stop(true,false).animate({'scrollTop':$(this).prev('.fancy-radio-button').offset().top});
        });
        $('input[name=company_name]').bind('ajax-autocomplete-complete',function(event, data) {
            if(data.dossiernummer) {
                $('input#coc_no').val(data.dossiernummer).trigger('keyup');
                $('input#postal_code').val(data.postcode).trigger('keyup');
                $('input#street_no').val(data.huisnummer).trigger('keyup');
                $('input#street_no_adding').val(data.huisnummertoevoeging).trigger('keyup');
                $('input#street').val(data.straat).trigger('keyup');
                $('input#city').val(data.plaats).trigger('keyup');
                //$('input#phone').focus();
            }
        });
        $('.scroll-screen#screen-2').bind('on-show',function() {
            $('input[name=profession]').not(':disabled').val('').focus();
        });
        $('.scroll-screen#screen-3').bind('on-show',function() {
            var profession_string = $('input#profession').val();
            if(profession_string.length == 0) {
                profession_string = "vakman";
            }
            $('span.chosen-profession').html(profession_string.toLowerCase());
        });
        $('.scroll-screen#screen-4').bind('on-show',function() {
            //Save the profile
            var loading_div = $(this).find('.loading');
            var show_div = $(this).find('.after_success');
            var data = $('form.profile_form').serializeJSON();
            $.post('/newProfile',data,function(returnData){
                setTimeout(function() {
                    if(returnData.url != undefined) {
                        setCookie("newProfile",returnData,1);
                        loading_div.css({'overflow':'hidden','height':loading_div.height(),'width':loading_div.width()}).animate({'height':0},500,function() {
                            show_div.fadeIn(500,function() {
                                setTimeout(function() {
                                    //alert("Dit is het einde van de test. Laat ons weten wat je bevindingen zijn. Het systeem stuurt je nu door naar de profielpagina, maar die hoef je (nog) niet te testen.");
                                    window.location.href = returnData.url;
                                },100);
                            });
                        });
                    }
                },2000);
            });
        });
        //$('.fancy-radio-button[data-input-name=account_type]').bind('custom-change',function() {
        //    alert('a');
        //});
    }
});
//function set_progress(progress_bar, value)
//{
//    var $ = jQuery.noConflict();
//    var speed = 500;
//    var current_value = progress_bar.val();
//    value = value * 100;
//    var diff = value - current_value;
//    $({countNum: current_value}).animate({countNum: value}, {
//        duration: speed,
//        specialEasing:'easeInOutBack',
//        step: function() {
//            progress_bar.val(Math.round(this.countNum));
//        },
//        complete: function() {
//            progress_bar.val(Math.round(this.countNum));
//        }
//    });
//
//}
//
//function reset_company_fields() {
//    var $ = jQuery.noConflict();
//    var creator_wrapper = $('.profile-creator-wrapper');
//    creator_wrapper.find('input#company_name').val('').trigger('keyup');
//    creator_wrapper.find('input#city').val('').trigger('keyup');
//    creator_wrapper.find('input#postal_code').val('').trigger('keyup');
//    creator_wrapper.find('input#street').val('').trigger('keyup');
//    creator_wrapper.find('input#street_no').val('').trigger('keyup');
//    creator_wrapper.find('input#street_no_adding').val('').trigger('keyup');
//}
//function open_profile_creator() {
//    var $ = jQuery.noConflict();
//    var creator_wrapper = $('.profile-creator-wrapper');
//    $('.register-button-wrapper').stop(true,false).fadeOut(500);
//    var to_height = creator_wrapper.find('.profile-creator-container').height()+78 /*78 for captcha that isnt loaded yet*/;
//    creator_wrapper.height(to_height);
//    var heading = $('.profile-creator-heading-wrapper');
//    to_height = heading.find('.profile-creator-heading').height();
//    heading.height(to_height);
//    creator_wrapper.find(":input").removeAttr('disabled');
//}
//function close_profile_creator() {
//    var $ = jQuery.noConflict();
//    var creator_wrapper = $('.profile-creator-wrapper');
//    $('.register-button-wrapper').stop(true,false).fadeIn(500);
//    creator_wrapper.height(0);
//    $('.profile-creator-heading-wrapper').height(0);
//    creator_wrapper.find(":input").attr('disabled','disabled');
//}

function getOrientation(file, callback) {
    var $ = jQuery.noConflict();
    var reader = new FileReader();
    reader.onload = function(e) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8) return callback(-2);
        var length = view.byteLength, offset = 2;
        while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) {
                if (view.getUint32(offset += 2, false) != 0x45786966) return callback(-1);
                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                    if (view.getUint16(offset + (i * 12), little) == 0x0112)
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
            else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
}
function register_validate_screen(screen_no, callback) {
    var $ = jQuery.noConflict();
    if(screen_no == 1) {
        //Check if it exists
        var input = $('input#coc_no');
        var phone = $('input#phone');
        var chosen_coc = input.val();
        $.get('/ajax/model/Profile/search', {'field':'coc_no','value':chosen_coc},function(data) {
            if(data == 0) {
                //it doesnt exist, so do the callback
                input.prev('span.error').hide();

                //Check phone number
                if(validate_phone_number(phone.val()) === true) {
                    callback();
                } else {
                    phone.prev('span.error').show();
                    phone.select().focus();
                }
            } else {
                input.prev('span.error').show();
                input.select().focus();
            }
            if(!window.first_for_phone_check_has_been_done) {
                //console.log('event');
                phone.keyup(function() {
                    $(this).prev('span.error').hide();
                });
                window.first_for_phone_check_has_been_done = true;
            }
            if(!window.first_for_co_check_has_been_done) {
                //console.log('event');
                input.keyup(function() {
                    $(this).prev('span.error').hide();
                });
                window.first_for_co_check_has_been_done = true;
            }
        });
        return true;
    }
    return true;
}