jQuery(document).ready(function($) {
    $('.edit-element').click(function(e) {
        e.preventDefault();
        var p = $(this).parents('.static-content');
        if(p.next().hasClass('edit-content')) {
            p.hide();
            p.next().show();
            p.next().find(':input:first').focus();
        }
    });
    $('.close-element').click(function(e) {
        e.preventDefault();
        var p = $(this).parents('.edit-content');
        p.prev().show();
        p.hide();
        //p.find('.static-content').show();
        //p.find('.edit-content').hide();
    });
    $('.edit-content form').submit(function(e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr('action');
        $.post(url,form.serializeObject(),function(data) {
            form.parent().find('.close-element').trigger('click');
            if(form.attr('data-reload-after-submit') == 'true') {
                location.reload();
            }
        });
    });
    $('.edit-content').hide();
});