jQuery(document).ready(function($) {
    if($('body').hasClass('job-edit')) {
        $('.image-upload').attr('data-upload-complete','job_image');
    }
    if($('body').hasClass('show_job')) {
        var deleted = 0;
        $('.saved-picture').bind('option-delete',function() {
            deleted++;
            var pic = $(this).attr('data-id');
            var form = $(this).parents('form');
            form.append('<input type="hidden" name="delete_pictures[]" value="'+pic+'" />');
            $(this).parent().remove();
            $('.image-upload-wrapper').show();
            $('.image-upload.multiple').attr('data-max-files',deleted).trigger('max-files-changed');
        }).bind('option-default', function() {
            var pic = $(this).attr('data-id');
            var form = $(this).parents('form');
            form.append('<input type="hidden" name="default_picture" value="'+pic+'" />');
            form.find('.saved-picture').attr('data-is-default', 0);
            $(this).attr('data-is-default', 1);
        });
    }
});
function job_image_upload_complete(file,data)
{
    var path = file.xhr.response;
    var location = document.location.toString().replace(document.location.hash,'');
    if(location.split("").reverse().join("").substr(0,1) != '/') location=location+'/';
    location=location+'addPhoto';
    var $ = jQuery.noConflict();
    $.post(location,{'file_path':file.xhr.response,'_token':$('.image-upload').parents('form').find('input[name=_token]').val()},function(data){
        document.location.reload();
    });
}