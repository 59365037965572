jQuery(document).ready(function($) {
    var text = '<h1 class="text-center">Hoera, je bent helemaal klaar voor Bullseye!</h1>' +
            '<p class="large-text">Dat was ‘m! Vanaf nu kan je lekker achterover zitten en wachten op de vele aanvragen. Want wie wil nou niet zo’n fantastische <span class="chosen-profession">timmerman</span> inhuren?</p>' +
            '<p class="large-text">Succes met je eerste opdracht via Bullseye {{ $user->first_name }}!</p>' +
            '<p class="large-text">Mocht je nog vragen hebben, dan horen we dat graag.<br /><br /></p>';
    if(!$('body').hasClass('profile_view')) {
        return;
    }
    //Check if the profile is new
    //setCookie("newProfile",JSON.parse('{"user_id":87,"company_name":"DII Clearwater B.V.","coc_no":"66815681","city":"Weesp","postal_code":"1381CZ","street":"Aetsveldselaan","street_no":"1","street_no_adding":"","phone":"","profession_id":"49","profession_string":"Behanger","active":true,"unique_id":12188828,"province":"Noord-Holland","province_code":"NH","lat":52.3037753,"lng":5.0377203,"updated_at":"2017-01-02 14:38:10","created_at":{"date":"2017-01-02 14:38:10.000000","timezone_type":3,"timezone":"Europe/Amsterdam"},"id":38,"slug":"dii-clearwater-bv-weesp","profile_image_id":88,"skill_names":["Kitten","Binnenschilderwerk","Behangen"],"location_string":"Weesp, NH","picture":"/uploads/c7e1249ffc03eb9ded908c236bd1996d/58683fc629b0ddecabfb2b80d2f5fd51.jpg","thumbnail":"/uploads/c7e1249ffc03eb9ded908c236bd1996d/58683fc629b0ddecabfb2b80d2f5fd51_300x300_cropped.jpg","url":"http://localhost:8000/vakman/dii-clearwater-bv-weesp","created_at_formatted":"02-01-2017","skills":[],"user":{"id":87,"name":"","email":"kpnudhmy@sharklasers.com","password_length":7,"role_id":1,"gender":"M","first_name":"Gekke","last_name":"Henkie","company_id":null,"created_at":"2017-01-02 14:35:21","updated_at":"2017-01-02 14:35:36","last_login":{"date":"2017-01-02 14:35:36.000000","timezone_type":3,"timezone":"Europe/Amsterdam"},"active":0,"skill_submissions":[]}}'),1);


    var newProfile = getCookie('newProfile');
    //console.log(JSON.stringify(newProfile));
    if(newProfile != undefined) {

        $.iGetModel('Profile','get_attribute',{'attribute':'user','id':newProfile.id},function(user) {
            if(newProfile.url == window.location.toString()) {
                logo_popup("Hoera, je Bullseye profiel is aangemaakt!",'<p class="large-text text-left">Goed gedaan '+user.first_name+'! Je hebt nu een Bullseye profiel. Natuurlijk wil je nog een stukje over jezelf schrijven en aangeven wat voor opdrachten je al hebt gedaan. Daar heb je nu rustig de tijd voor.</p>' +
                    '<p class="large-text text-left">Mocht je nog vragen hebben, dan horen we die graag via het contactformulier onderaan de site of via 0800 - 285 573 93.<br /><br /></p>' +
                    '<a class="button">Sluiten</a>',function() {
                        $('a.button').click(function(e) {
                            e.preventDefault();
                            close_logo_popup();
                        },function() {
                            deleteCookie("newProfile");
                        });
                })
            }
        });
    }

    var message_sent = getCookie('message_sent',false);
    if(message_sent === true) {
        logo_popup("Bericht verzonden","<p class='large-text'>Je bericht is succesvol verzonden.</p><a class='button'>Sluiten</a>",function() {
            $('a.button').click(function(e) {
                e.preventDefault();
                close_logo_popup();
            },function() {
                deleteCookie("message_sent");
            });
        });
    }

    $('.project-image').bind('option-delete',function() {
        var delete_url = $(this).attr('data-delete-url');
        confirm_modal({
            message: "Weet u zeker dat u dit project wilt verwijderen?",
            confirm: delete_url
        })
    });
    $('.profile-picture-wrapper img').bind('option-camera',function() {
        var id = get_unique_id();
        $('body').append('<form action="/profile/update-profile-image" method="post" enctype="multipart/form-data" style="position:absolute; top: -100px;"><input type="file" name="file" id="'+id+'" /></form>');
        $('input#'+id).parents('form').formLoader();
        $('input#'+id).click().change(function() {
            $(this).parents('form').submit();
        });
    });
});

function zoom_profile_picture (sender) {
    var $ = jQuery.noConflict();
    var elm = $(sender);
    var src = elm.attr('src');
    var id = get_unique_id();
    var wrapper = $('<div class="image-zoom-wrapper" id="'+id+'"><img src="'+src+'" /></div>');
    $('body').append(wrapper);
    setTimeout(function() {
        wrapper.addClass('show');
    }, 50);
    wrapper.click(function() {
        wrapper.removeClass('show');
        setTimeout(function() {
            wrapper.remove();
        }, 500);
    });
}