jQuery(document).ready(function($) {
    if($('body').hasClass('profiles')) {
        $('form.filters-form').on('filter_change',function(e, filters, order) {


            var run_after_filter_set = function() {
                filters.s = get_search_value();

                fetch_the_data('/ajax/profiles/search', filters, order, function(data, row) {
                    //Company name
                    row.find('h3 a').html(data.company_name);

                    //Url
                    row.find('a').attr('href',data.url);

                    //Image
                    row.find('img').attr('src',data.thumbnail);

                    //Profession
                    row.find('.professions-wrapper').html(data.profession_string);

                    //Location info
                    row.find('.location-info').html(data.location_string);

                    //Created at
                    row.find('.created_at').html(data.created_at_formatted);

                    //Views
                    row.find('.views').html(data.views);
                });
            }.bind([filters,order]);

            var current_url = window.location.toString().split('#')[0];


            run_after_filter_set();
            /**
             * Wat voor vakman zoek je? Tijdelijk uitgeschakeld op 02-01-2017
             */
            /*
            if((Object.keys(filters).length == 0 || (Object.keys(filters).length == 1 && filters.postalcode != undefined)) && getCookie("last_page_before_search_profiles") != current_url) {
                $('.form-loader-overlay').hide();
                logo_popup("Wat voor vakman zoek je?",'<input type="text" id="mini_search" data-squde-select-model="Profession" placeholder="Kies een soort vakman..." /><br /><span style="font-size: 12px;" class="text-center"><a href="#" onclick="return profilesWithoutFilters()">Ik heb nog geen idee</a></span>', function() {
                    $('#mini_search').bind('ajax-autocomplete-complete', function(e, value) {
                        if(value.id > 0) {
                            window.location.href = current_url+'#filters[professions][]='+value.id;
                            window.location.reload();
                        } else {
                            setCookie("last_page_before_search_profiles",current_url,1);
                            window.location.href = current_url;
                        }
                    });
                });
            } else {
                setCookie("last_page_before_search_profiles",current_url,1);
                run_after_filter_set();
            }
            */

        });
    } else {
        setCookie("last_page_before_search_profiles",window.location.toString().split('#')[0],1);
    }
});
function profilesWithoutFilters() {
    var $ = jQuery.noConflict();
    var current_url = window.location.toString().split('#')[0];
    setCookie("last_page_before_search_profiles",current_url,1);
    window.location.href = current_url;
}