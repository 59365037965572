jQuery(document).ready(function($) {
    var wait_for_close = false;
    var search_form = $('.search-bar-wrapper').find('form');
    var found_object = false;

    var search_data = getSearchData();
    if(search_data !== false) {
        $.each(search_data,function(key,value) {
            if(typeof value == 'object') {
                $.each(value, function(key,value) {
                    search_form.append('<input type="hidden" name="extra['+key+']" />');
                    search_form.find('input[name=\'extra['+key+']\']').val(value);
                });

            } else {
                search_form.find(':input[name='+key+']').val(value);
            }
        });
    }

    $('input#search')
        .width($(this).width())
        .attr('data-ajax-autocomplete-url','/ajax/helper/search_all_items')
        .attr('data-ajax-autocomplete-params','field=identifier')
        .attr('data-ajax-autocomplete-compare','identifier')
        .attr('data-ajax-autocomplete-min-length',1)
        .attr('data-ajax-autocomplete-run-on-init',0)
        .ajaxAutoComplete(process_search_result)
        .keydown(function(e) {
            if(found_object === false) {
                $(this).parents('form').find('input[name^=extra]').remove();
            }
            if(e.keyCode == 13) {
                search_form.submit();
            }
    });

    $('.search-bar-wrapper').find(':input').each(function() {
        $(this).focus(function() {
                clearTimeout(wait_for_close);
                $(this).parents('.search-bar-wrapper').addClass('has-focus');
            })
            .blur(function() {
                var input = $(this);
                wait_for_close = setTimeout(function() {
                    var close = true;
                    $('.search-bar-wrapper').find(':input').each(function() {
                        if($(this).is(':focus')) {
                            close = false;
                        }
                    });
                    if(close === true) {
                        input.parents('.search-bar-wrapper').removeClass('has-focus');
                    }
                },2500);
            });
    });
    search_form.submit(function(e) {
        var formData = $(this).serializeJSON();
        saveSearchData(formData);
    });
    function process_search_result(data) {
        if(typeof data == 'string') {
            found_object = false;
            return;
        }
        found_object = data;
        $.each(data,function(key,value) {
            if(search_form.find('input[name=\'extra['+key+']\']').length == 0) {
                search_form.append('<input type="hidden" name="extra['+key+']" />');
            }
            search_form.find('input[name=\'extra['+key+']\']').val(value);
        });
    }
    function saveSearchData(data) {
        var str = JSON.stringify(data);
        setCookie('searchData',str,99);
    }
    function getSearchData() {
        var str = getCookie('searchData',str);
        if(str.length > 0) {
            return JSON.parse(str);
        }
        return false;
    }

});
function get_search_value() {
    var $ = jQuery.noConflict();
    var search_form = $('.search-bar-wrapper').find('form');
    if(search_form.find('input[name^=extra]').length == 0 && search_form.find('input#search').val() != undefined && search_form.find('input#search').val().length >0) {
        return search_form.find('input#search').val();
    }
    return null;
}