jQuery(document).ready(function($) {
    $('[data-login-first]').each(function() {
        var elm = $(this);
        if(!is_logged_in()) {
            $.get('/auth/loginFirstView', function(data) {
                elm.after(data);
                var w = elm.next();
                var set_recaptcha = function() {
                    var captcha_elm = w.find('.g-recaptcha');
                    if(captcha_elm.length > 0 && captcha_elm.is(':visible')) {
                        if(captcha_elm.html().length == 0) {
                            grecaptcha.render(captcha_elm[0], {
                                'sitekey' : captcha_elm.attr('data-sitekey')
                            });
                        } else {
                            grecaptcha.reset();
                        }
                    }
                };
                var check_if_active = 0;
                var do_if_logged_in = function() {
                    w.fadeOut(500, function() {
                        w.remove();
                        window.location.reload();
                    });
                };
                var start_checking_for_active_user = function() {
                    check_if_active = setInterval(function() {
                        $.ajax({
                            url: '/auth/ajaxGetUser',
                            dataType: 'JSON',
                            type: 'GET',
                            success: function(user) {
                                if(user.active == 1) {
                                    stop_checking_for_active_user();
                                    do_if_logged_in();
                                }
                            }
                        });
                    },500);
                };
                var stop_checking_for_active_user = function() {
                    clearInterval(check_if_active);
                };
                var show_screen = function (screen) {
                    stop_checking_for_active_user();
                    var screens = ['login', 'register', 'forgot', 'activate', 'mail-sent'];
                    screens.forEach(function(s) {
                        w.find('.row.'+s+'-form').hide();
                    });
                    w.find('.row.'+screen+'-form').show();

                    set_recaptcha();
                };
                w.find('a[data-login-first-button]').click(function(e) {
                    e.preventDefault();
                    show_screen($(this).attr('data-login-first-button'));
                });


                w.find('form').submit(function(e) {
                    e.preventDefault();
                    var form = $(this);
                    form.isLoading();
                    var url = form.attr('action');
                    var set_error = function(str) {
                        if(str == undefined) {
                            form.find('.error-wrapper').removeClass('shown');
                            form.find('.error-wrapper').find('.column').empty();
                        } else {
                            form.find('.error-wrapper').addClass('shown');
                            form.find('.error-wrapper').find('.column').html(str);
                        }
                    };
                    set_error();
                    $.ajax({
                        url: url,
                        data: form.serializeJSON(),
                        type: 'POST',
                        success: function(data) {
                            form.doneLoading();
                            set_recaptcha();
                            if(form.hasClass('login_form')) {
                                if(data == 1) {
                                    do_if_logged_in();
                                } else {
                                    set_error("Combinatie email / wachtwoord is onjuist.");
                                }
                            } else if(form.hasClass('register_form') && data == 1) {
                                show_screen('activate');
                                w.find('.row.activate-form').find('.loader-wrapper').isLoading();
                                start_checking_for_active_user();
                            } else if(form.hasClass('password_form') && data == 1) {
                                show_screen("mail-sent");
                                w.find('.row.mail-sent-form').find('.loader-wrapper').isLoading();
                                start_checking_for_active_user();
                            } else {
                                console.log(data);
                            }
                        },
                        error: function(e) {
                            form.doneLoading();
                            set_recaptcha();
                            var errors = [];
                            for(var key in e.responseJSON) {
                                errors.push(e.responseJSON[key][0]);
                            }
                            set_error(errors.join("<br />"));
                        }
                    })
                });
            });
        }
    });
    function is_logged_in() {
        return $('body').hasClass('is-logged-in');
    }
});