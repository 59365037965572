jQuery(document).ready(function($) {
    if($('body').hasClass('add_job') || $('body').hasClass('edit_job')) {
        triggerBusinessFields();
        var xhr = false;
        var do_not_get_suggestions = false;
        //Select professions based on project-type
        //$('select#project_type').change(function() {
        //    var v = $(this).val();
        //    if(!isNaN(v)) {
        //        $.iGetModel('ProjectType','get_attribute',{'attribute':'professions','id':v},function(data) {
        //            var professions_selector = $('select#professions');
        //            professions_selector.empty();
        //            for(var key in data) {
        //                professions_selector.append('<option value="'+data[key]['id']+'" selected="selected">'+data[key]['name']+'</option>');
        //            }
        //            professions_selector.trigger('change');
        //        });
        //    }
        //});
        if($('input#name').val() != $('input#name').attr('value')) {
            $('input#name').val('');
        }
        $('input#name').focus().keyup(function(e) {
            var v = $(this).val();
            if(do_not_get_suggestions === false) {
                if(xhr !== false) {
                    xhr.abort();
                }
                xhr = $.ajax({
                    url: '/ajax/helper/get_job_data_suggestions',
                    data: {data:v},
                    success: function(data) {
                        //console.log(data);
                        var projInput = $('select#project_type');
                        var profInput = $('select#professions');
                        var skillInput = $('select#skills');
                        profInput.empty();
                        if(data.project_types) {
                            projInput.empty();
                            projInput.append('<option value="'+data.project_types[0]['id']+'" selected="selected">'+data.project_types[0]['name']+'</option>');
                            projInput.trigger('change');
                        }
                        if(data.professions) {
                            profInput.empty();
                            $.each(data.professions, function(key,value) {
                                profInput.append('<option value="'+value['id']+'" selected="selected">'+value['name']+'</option>');
                            });
                            profInput.trigger('change');
                        }
                        if(data.skills) {
                            skillInput.empty();
                            $.each(data.skills, function(key,value) {
                                skillInput.append('<option value="'+value['id']+'" selected="selected">'+value['name']+'</option>');
                            });
                            skillInput.trigger('change');
                        }
                    }
                });
            }
        }).blur(function() {
            do_not_get_suggestions = true;
        });

        var project_type_ajax = false;
        $('select#project_type').change(function() {
            if(project_type_ajax !== false) {
                project_type_ajax.abort();
            }
            var p = $('p.insurance-text');
            project_type_ajax = $.ajax({
                url: '/ajax/projectType/insurance_setting/',
                data: {id: $(this).val()},
                success: function(data) {
                    if(data == 1) {
                        p.show();
                    } else {
                        p.hide();
                    }
                }
            })
        });

        //En/disable the inputs for email or phone
        $('input[name^=contact]').change(function() {
            var hidden_box = $(this).parent().find('.hidden-by-overflow-wrapper:first');
            if($(this).is(':checked')) {
                hidden_box.trigger('open');
            } else {
                hidden_box.trigger('close');
            }
        }).trigger('change');
        setTimeout(function() {
            $('input[name^=contact]').trigger('change');
        },500);


        var hash = window.location.hash.substr(1);
        if(hash.length > 0) {
            console.log(hash);
            $('fieldset[id]').hide();
            $('fieldset#'+hash+'_fields').show();
        }

        $('textarea#description').keyup(function() {
            if($(this).val().toLowerCase() == "test") {
                $(this).val('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at purus nibh. Cras metus nulla, vestibulum in auctor ac, fermentum vitae tellus. Donec sed aliquam nisl. Sed eu leo id est pretium euismod. Nulla id justo at mi venenatis volutpat. Fusce nisi leo, placerat id condimentum a, accumsan vitae tortor.');
                $(this).blur();
            }
        });



        $('input[name=company_name]').bind('ajax-autocomplete-complete',function(event, data) {
            if(data.dossiernummer) {
                $('input#coc_no').val(data.dossiernummer).trigger('keyup');
            }
        });
    }


    //add_job();
});

function triggerBusinessFields() {
    var $ = jQuery.noConflict();
    var c = $('input#is_professional').is(":checked");
    var w = $('.business-fields-wrapper');
    if(c === true) {
        w.show();
        w.find(':input:first').focus();
        w.find(':input').removeAttr('disabled');
    } else {
        w.hide();
        w.find(':input').attr('disabled','disabled');
    }
}

//function add_job() {
//    var $ = jQuery.noConflict();
//    $.get('/ajax/job/add/',function(html) {
//        $('body').append('<div class="new-job-wrapper"></div>');
//        $('.new-job-wrapper').html(html);
//
//        $('.new-job-scroller-wrapper').scrollLeft(0);
//        $('.job-lightbox-background').click(function() {
//            close_new_job();
//        });
//
//        $(window).bind('resize.jobLightbox',function() {
//            var wrapper = $('.new-job-scroller-wrapper');
//            var active = wrapper.find('.active');
//            if(active.length > 0 && active.index() > 0) {
//                var offset = wrapper.width() * active.index();
//                wrapper.scrollLeft(offset);
//            }
//        });
//    });
//    return false;
//}
//function close_new_job() {
//    var $ = jQuery.noConflict();
//    $('.new-job-wrapper').fadeOut(500,function() {
//        $(this).remove();
//    });
//    $('*').unbind( ".jobLightbox" );
//    $(document).unbind( ".jobLightbox" );
//    $(window).unbind( ".jobLightbox" );
//    return false;
//}
//
//function next_job_page() {
//    scroll_job_page('r');
//    return false;
//}
//function prev_job_page() {
//    scroll_job_page('l');
//    return false;
//}
//function scroll_job_page(dir) {
//    var $ = jQuery.noConflict();
//    var c = $('.new-job-scroller-wrapper');
//    var w = $('.new-job-scroller-container');
//    var a = w.children('.active');
//    var t = false;
//    if(dir == 'r') {
//        t = a.next();
//    } else {
//        t = a.prev();
//    }
//    if(t.length == 0) {
//        return false;
//    }
//    var offset = t.outerWidth() * t.index();
//    a.removeClass('active');
//    t.addClass('active');
//    var perc = Math.round((t.index()+1) / parseInt(c.attr('data-contains')) * 100);
//    $('.job-progress-wrapper').attr('data-progress',perc);
//    c.animate({'scrollLeft':offset});
//}
//function load_matching_job_data() {
//    var $ = jQuery.noConflict();
//    var str = $('#job_description_input').val();
//    $.get('/ajax/helper/get_job_data_suggestions',{data:str},function(data){
//        var professions = [];
//        var profInput = $('input#job_professions_input');
//        if(data.professions) {
//            $.each(data.professions, function(key,value) {
//                professions[professions.length] = value;
//            });
//            profInput.val(professions);
//        }
//        profInput.SqudeSelectChildren(data.professions);
//        next_job_page();
//    });
//}