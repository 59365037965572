function logo_popup(title, html, after_place, after_close) {
    var $ = jQuery.noConflict();
    $('body').append('' +
        '<div class="logo-popup-background"></div>' +
        '<div class="logo-popup-wrapper">' +
            '<div class="table">' +
                '<div class="cell">' +
                    '<div class="logo-popup">' +
                        '<div class="row">' +
                            '<div class="column text-center">' +
                                '<div class="logo medium hide-for-small-only" style="">' +
                                    '<div class="logo-foreground"></div>' +
                                    '<div class="logo-pupil-wrapper">' +
                                        '<div class="logo-pupil"></div>' +
                                    '</div>' +
                                    '<div class="logo-background"></div>' +
                                '</div>' +
                            '</div>' +
                            '<div class="row">' +
                                '<div class="column text-center">' +
                                    '<h1>'+title+'</h1>' + html +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>' +
    '</div>');
    if(typeof after_place == 'function') {
        after_place();
    }
    $('.logo-popup-wrapper').not('.logo-popup-wrapper > *').click(function() {
        close_logo_popup(after_close);
    });


    /**
     * Veroorzaakte problemen bij nieuw profiel.
     * Heb het moeten bouwen om de link "Ik heb geen idee" bij "Wat voor vakman zoek je?" te laten werken, maar die moest worden uitgeschakeld op 02-01-2017
     */
    //$('.logo-popup-wrapper').not('.logo-popup-wrapper > *').click(function() {
    //    var events = $.data( $('body').get(0), 'events' ).click;
    //    if($(this).parents('.logo-popup-wrapper').length == 0 && $(this).parents('.autocomplete-option-list-wrapper').length == 0 && $(this).parent().html().split('fb_reset').length == 1) {
    //        //console.log($(this).parent().html());
    //        close_logo_popup(after_close);
    //    }
    //});
}
function close_logo_popup(after_close) {
    var $ = jQuery.noConflict();
    $('.logo-popup-background, .logo-popup-wrapper').fadeOut(500,function() {
        $(this).remove();
        if(typeof after_place == 'function') {
            after_close();
        }
    });
}