(function( $ ){
    $.fn.captchaModal = function() {
        this.each(function() {
            var elm = $(this);
            var form = $(this).parents('form:first');
            if(form.length == 0) {
                console.log("Can't secure no form, bro");
            } else {
                if(!form.hasClass('captcha-secured')) {
                    elm.css({'overflow':'hidden','height':0});
                    form.bind('submit.recaptcha', function(e) {
                        e.preventDefault();
                        $(this).addClass('submitted');
                        var elm = $(this).find('.captcha-container');
                        elm.removeAttr('style');
                        show_modal("Beveiliging",elm,false,{},$(this));
                    });
                    form.addClass('captcha-secured');
                }
            }
        });
        return this;
    };
})( jQuery );
jQuery(document).ready(function($) {
    jQuery('.captcha-container').captchaModal();
});
function bullseye_recaptcha_callback(a) {
    var $ = jQuery.noConflict();
    var form = $('form.submitted:first');
    var textarea = $('.reveal').find('textarea#g-recaptcha-response');
    textarea.html(a);
    $('.reveal').find('input[type=submit]').remove();
    form.append(textarea).unbind('submit.recaptcha').submit();
}