jQuery(document).ready(function($) {
    var wrapper = $('.no-margin-page-wrapper');
    if(wrapper.length > 0) {
        wrapper.animate({'scrollLeft':$(window).width()},0,function(){
            var wait = 0;
            wrapper.scroll(function(e) {
                clearTimeout(wait);
                wait = setTimeout(function() {
                    var pos = wrapper.scrollLeft();
                    //Measure visibily
                    var total = wrapper.width();
                    var left = wrapper.find('.sidebar').width();
                    var right = wrapper.find('.main-content').width();
                    var active = 'l';
                    if(pos > left) {
                        active = 'r';
                    }
                    var right_viewed = (pos-left)/$(window).width()*100*-1;
                    //Voor als Android oid moeilijk gaat doen, let er alleen op dat hij nog een beetje verbeterd moet
                    //worden. De button werkt nml niet met de addclass als pos < 0 of pos > 100 was.
                    //var left_viewed = 100-pos/left*100;
                    //if(left_viewed > 0 && left_viewed < 100) {
                    //    if(right_viewed > 50) {
                    //        wrapper.removeClass('open-sidebar');
                    //    } else {
                    //        wrapper.addClass('open-sidebar');
                    //    }
                    //    $('.open-sidebar-wrapper').trigger('click');
                    //}

                    if(right_viewed > 50) {
                        wrapper.removeClass('open-sidebar');
                    } else {
                        wrapper.addClass('open-sidebar');
                    }
                    $('.open-sidebar-wrapper').trigger('click');
                },250);
            });
        });
        wrapper.find('.sidebar').css({'margin-left':0});
        $('.open-sidebar-wrapper').click(function(e) {
            if(wrapper.hasClass('open-sidebar')) {
                wrapper.removeClass('open-sidebar');
                wrapper.stop(true,true).animate({'scrollLeft':$(window).width()});
            } else {
                wrapper.addClass('open-sidebar');
                wrapper.stop(true,true).animate({'scrollLeft':0});
            }
        });
    }
    $( window ).resize(function() {
        if(!wrapper.hasClass('open-sidebar')) {
            wrapper.animate({'scrollLeft':$(window).width()},250);
        }
    });
});