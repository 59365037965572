jQuery(document).ready(function($) {
    if($('.reviews-listing-wrapper').length > 0) {
        $('select#review_order').change(function() {
            load_the_reviews();
        }).trigger('change');

        if(window.location.href.length > 0) {
            var p = window.location.href.split('#write-review-for=');
            if(p.length == 2) {
                add_review_for(p[1]);
            }
        }
    }
});
function load_the_reviews(limit) {
    var $ = jQuery.noConflict();
    var w = $('.reviews-listing-wrapper');
    var limit_reached_container = $('.if-limit-reached');
    var original_limit = parseInt(w.attr('data-limit'));
    var total_limit = parseInt(w.attr('data-total-limit'));
    limit_reached_container.hide();
    if(limit == undefined) {
        limit = original_limit;
    }
    var id = $('.profile-container').attr('data-id');
    if(id == undefined) {
        return;
    }
    var order = $('select#review_order').val();
    w.formLoader(true);
    $.get('/ajax/reviews/'+id,{order:order,limit:limit},function(data) {
        w.html(data);
        if(total_limit > limit) {
            limit_reached_container.show();
        }
        reload_textarea_max_size();
    });
    return false;
}
function specific_review_expander(review_id) {
    var $ = jQuery.noConflict();
    var wrapper = $('[data-review-id='+review_id+']');
    var c = wrapper.find('.review-parts-wrapper');
    if(c.hasClass('closed')) {
        wrapper.find('[data-state=closed]').hide();
        wrapper.find('[data-state=open]').show();
        c.removeClass('closed');
    } else {
        wrapper.find('[data-state=closed]').show();
        wrapper.find('[data-state=open]').hide();
        c.addClass('closed');
    }
}
function vote_review(id, up_or_down) {
    var $ = jQuery.noConflict();
    var wrapper = $('[data-review-id='+id+']');
    var before = wrapper.find('.before-vote');
    var after = wrapper.find('.after-vote');
    var p = before.parent();
    var h = p.height();

    $.iPostModel('Review','update',{'id':id, data: {'vote':up_or_down}},function(data) {
        wrapper.find('.votes').html(data.votes);
        p.css({'overflow':'hidden','height': h}).animate({'height':0},function() {
            after.show();
            before.remove();
            var h = after.height();
            p.animate({'height':h});
        });
        return false;
    });
    return false;
}
function add_review_for(id) {
    jQuery.squdePopup("/ajax/reviews/add/"+id, function(elm, popup) {
        elm.find('form').bind('ajax-success', function(e, data) {
            if(window.location.href.length > 0) {
                var p = window.location.href.split('#');
                window.location.href = p[0];
            } else {
                window.location.reload();
            }
        });
    });
    return false;
}
//function close_new_review() {
//    var $ = jQuery.noConflict();
//    $('.new-review-wrapper').fadeOut(500,function() {
//        $(this).remove();
//    });
//    $('*').unbind( ".reviewLightbox" );
//    $(document).unbind( ".reviewLightbox" );
//    $(window).unbind( ".reviewLightbox" );
//    return false;
//}
//function next_review_page() {
//    //scroll_review_page('r');
//    return false;
//}
//function prev_review_page() {
//    //scroll_review_page('l');
//    return false;
//}
//function scroll_review_page(dir) {
//    var $ = jQuery.noConflict();
//    var c = $('.new-review-scroller-wrapper');
//    var w = $('.new-review-scroller-container');
//    var a = w.children('.active');
//    var t = false;
//    if(dir == 'r') {
//        t = a.next();
//    } else {
//        t = a.prev();
//    }
//    if(t.length == 0) {
//        return false;
//    }
//    var offset = t.outerWidth() * t.index();
//    a.removeClass('active');
//    t.addClass('active');
//    var perc = Math.round((t.index()+1) / parseInt(c.attr('data-contains')) * 100);
//    $('.review-progress-wrapper').attr('data-progress',perc);
//    c.animate({'scrollLeft':offset});
//}
function make_review_preview() {
    var $ = jQuery.noConflict();

    var w = $('.new-review-wrapper');

    var data = $('form.new-review-wrapper').serializeObject();
    $.post('/ajax/reviews/preview', data, function(data) {
        var p = w.find('.review-preview');
        p.html(data);
        p.find('.rating').ratingStars();

        setTimeout(function() {
            w.parent().trigger('scrollToScreen', 'next');
        },500);
    });
    return false;
}
//function submit_new_review() {
//    var $ = jQuery.noConflict();
//    var form = $('form.new-review-wrapper');
//    var data = form.serializeObject();
//    $.ajax(
//        {
//            url: '/ajax/reviews/save',
//            type: 'POST',
//            data: data,
//            success: function(data) {
//                $('.review-preview').html(data);
//                $('.review-preview').find('.rating').ratingStars();
//                window.location.reload();
//            },
//            error: function(error) {
//                for(var key in error.responseJSON) {
//
//                }
//            }
//        });
//    return false;
//}
function add_reaction(id) {
    var $ = jQuery.noConflict();
    var wrapper = $('[data-review-id='+id+']').find('.response-input-wrapper');
    var container = wrapper.find('.response-input-container');
    if(wrapper.height() == 0) {
        //closed
        wrapper.height(container.height());
        wrapper.find('textarea').focus();
    } else {
        wrapper.height(0);
    }
}
function submit_new_review_answer(id) {
    var $ = jQuery.noConflict();
    var wrapper = $('[data-review-id='+id+']');
    var form = wrapper.find('form.new-review-answer-form');
    var action = form.attr('action');
    $.post(action,form.serializeObject(),function(data) {
        load_the_reviews();
    });
    return false;
}