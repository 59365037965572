jQuery(document).ready(function($){
    if($('.images-wrapper').length > 0) {
        //Lightbox the big images
        $('.single-big-image-wrapper').find('a').click(function(e) {
            var url = $(this).attr('href');
            if(url.split('.')[url.split('.').length - 1] == 'pdf') {
                return true;
            }
            e.preventDefault();
            var id = get_unique_id();
            var size = "large";

            $('body').append('<div id="'+id+'" class="'+size+' reveal" data-reveal data-close-on-click="true" data-animation-in="fade-in-down fast ease-in" data-animation-out="fade-out-up fast ease-in">' +
                '<a class="close-reveal-modal" aria-label="Close" style="position: absolute; right: 25px;">&#215;</a>' +
                '<div class="squde-popup-html"></div>' +
                '</div>');
            var elm = $('#'+id);
            elm.find('.squde-popup-html').html('<img src="'+url+'" />');
            elm.find('.squde-popup-html').css('text-align', 'center');

            var popup = new Foundation.Reveal(elm);
            popup.open();
            elm.css('top',50);

            elm.find('.close-reveal-modal').click(function() {
                popup.close();
            }.bind(popup));
        });


        //Fix scroll position on window resize
        $(window).bind('resize.imagesWrapperScroll',function() {
            var w = $('.images-wrapper');
            var bW = w.find('.big-image-wrapper');
            var active = bW.find('.active');
            if(active.length > 0 && active.index() > 0) {
                var offset = active.width() * active.index();
                bW.scrollLeft(offset);
            }
        });
    }
});


function show_thumbnail_image(no, elm) {
    var $ = jQuery.noConflict();
    var a = $(elm);
    //console.log(a);
    var w = $('.images-wrapper');
    var bW = w.find('.big-image-wrapper');
    var n = w.find('.single-big-image-wrapper:eq('+(no)+')');
    var l = no * n.width();
    bW.stop(true,true).animate({scrollLeft: l},250,function() {
        var bW = $(this);
        var w = bW.parents('.images-wrapper');
        w.find('.active').removeClass('active');
        w.find('.single-big-image-wrapper:eq('+no+'), .single-thumbnail-wrapper:eq('+no+')').addClass('active');
    });
}