(function( $ ){
    $.fn.placeholderLabels = function() {
        var elm = this;
        var inputs = elm.find('input[type=text], input[type=password], input[type=date], input[type=int], input[type=email], textarea');
        inputs.each(function() {
            var i = $(this);
            var id = i.attr('id');
            if(id != undefined && id.length > 0) {
                var l = i.prevAll('label');
                if(l.length == 0) {
                    l = $('label[for='+id+']');
                }
                var compare = false;
                if($(this).attr('id')) {
                    compare = true;
                }
                if($(this).attr('id') == l.attr('for') || compare === false) {
                    i.attr('placeholder', l.text());
                    var viewing = false;
                    var hiding = false;
                    i.keyup(function(){
                        var i = $(this);
                        var l = i.prevAll('label');
                        var id = i.attr('id');
                        if(id.length > 0) {
                            if(l.length == 0) {
                                l = $('label[for='+id+']');
                            }
                            var v = $(this).val();
                            if(v.length == 0) {
                                if(hiding == false) {
                                    hiding = true;
                                    viewing = false;
                                    l.stop(true,false).animate({'opacity':0,'margin-left': l.width()*0.1*-1},function() {
                                        hiding = false;
                                    });
                                }
                            } else {
                                if(viewing == false) {
                                    hiding = false;
                                    viewing = true;
                                    l.stop(true,false).animate({'opacity':1,'margin-left': 0},function() {
                                        viewing = false;
                                    });
                                }
                            }
                        }
                    }).trigger('keyup');
                }
            }
        });
        return this;
    };
})( jQuery );