(function( $ ){
    $.fn.ratingStars = function() {
        this.each(function() {
            var elm = $(this);
            if(elm.attr('data-rating-stars') == undefined) {
                elm.attr('data-rating-stars',1);
                if(elm.children('.beye-rate-star').length == 0) {
                    for(var i = 1; i<= 5; i++) {
                        elm.append('<span class="beye-rate-star star-'+i+'"></span>');
                    }
                }

                if(elm.find('input').length > 0) {
                    //It's a settable rating
                    var input = elm.find('input');
                    elm.append(input);

                    input.change(function() {
                        var v = $(this).val();
                        set_rating(v);
                    });
                    var v = input.val();
                    set_rating(v);
                    elm.css('cursor','pointer');

                    elm.children().unbind('mouseenter').mouseenter(function() {
                        var c = $(this);
                        var temp_value = c.index()+1;
                        set_rating(temp_value);
                    }).click(function() {
                        var c = $(this);
                        var new_value = c.index()+1;
                        input.val(new_value).trigger('change');
                    });
                    elm.mouseleave(function(){
                        var input = elm.find('input');
                        var v = input.val();
                        set_rating(v);
                    });
                }
                function set_rating(no) {
                    elm.removeClass('rating-0').removeClass('rating-1').removeClass('rating-2').removeClass('rating-3').removeClass('rating-4').removeClass('rating-5');
                    elm.addClass('rating-'+no);
                }
            }
        });
        return this;
    };
})( jQuery );
jQuery(document).ready(function($) {
    jQuery( document ).unbind('ajaxComplete.ratingStars').bind('ajaxComplete.ratingStars',function() {
        setTimeout(function() {
            $('.rating').not('[data-rating-stars=1]').ratingStars();
        },100);
    });
});