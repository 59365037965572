function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function model_flash(model, action, data, sent_data) {
   var attr = Lang.get('global.item');
   model = model.toLowerCase();
   action = action.toLowerCase();
   if(Lang.has('global.'+model)) {
       attr = Lang.get('global.'+model);
   }
   attr = ucfirst(attr);

   var str = "";
   if(Lang.has('messages.'+action+'_with_name') && data!=undefined && data.name!=undefined &! (action == "attach" || action == "detach")) {
       str = Lang.get('messages.'+action+'_with_name',{'attribute':attr,'name':data.name});
   } else {
       if(action == "attach" || action == "detach") {

           var child_model = sent_data.field.substr(0,sent_data.field.length-1).toLowerCase();
           var child_attr = Lang.get('global.item');
           if(Lang.has('global.'+child_model)) {
               child_attr = Lang.get('global.'+child_model);
           }
           child_attr = ucfirst(child_attr);
           if(data.name==undefined) {
               str = Lang.get('messages.'+action,{'child':child_attr,'parent':attr.toLowerCase()});
           } else {
               str = Lang.get('messages.'+action+'_with_name',{'child':child_attr,'parent':attr.toLowerCase(),'name':data.name});
           }

       } else {
           str = Lang.get('messages.'+action,{'attribute':attr});
       }
   }

   bullseye_flash(str);
}
function bullseye_flash(str,type) {
   var $ = jQuery.noConflict();
   //Types can be: radius, success, alert, secondary
   if(type == undefined) {
       type = "success";
   }

   var offset = 0;
   if($('.bullseye-flash').length>0) {
       $('.bullseye-flash').each(function(){
           offset = offset+$(this).height();
       });
   }

   var id = bullseye_makeid();
   // var html = '<div class="bullseye-flash" id="'+id+'">' +
   //         '<div class="row collapse">' +
   //             '<div class="column small-12">' +
   //                 '<div class="alert-box '+type+'" data-alert>' +
   //                      '<span id="str"></span><a class="close" href="">×</a>' +
   //                 '</div>' +
   //             '</div>' +
   //         '</div>' +
   //     '</div>';
   //  console.log(id);
   var html = '' +
        '<div class="bullseye-flash" id="'+id+'">' +
            '<div class="row collapse">' +
                '<div class="column small-12">' +
                    '<div class="callout '+type+'">' +
                        '<button type="button" aria-label="Close alert" class="close-button">' +
                            '<span aria-hidden="true">×</span>' +
                        '</button>' +
                        '<span id="str"></span>' +
                    '</div>' +
                '</div>' +
            '</div>' +
        '</div>';
   $('body').prepend(html);
   var f = $('.bullseye-flash#'+id);
   f.find('span#str').html(str);
   f.find('button.close-button').click(function(e){
       e.preventDefault();
       bullseye_closeFlash(f);
   });
   f.css({'margin-top': f.height()*-1}).animate({'opacity':1,'margin-top':offset},function(){
        var wait_to_remove = setTimeout(function(){
            bullseye_closeFlash(f);
        },3000);
        if(str.length > 200) {
            clearTimeout(wait_to_remove);
        }
   });
}
function bullseye_closeFlash(f) {
   var $ = jQuery.noConflict();
   $('.bullseye-flash').each(function(){
       $(this).animate({'margin-top':parseInt($(this).css('margin-top')) - f.height()},function(){
           if($(this).attr('id') == f.attr('id')) {
               $(this).remove();
           }
       })
   });
}
function get_unique_id() {
    return bullseye_makeid();
}
function bullseye_makeid()
{
   var $ = jQuery.noConflict();
   var text = "";
   var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

   for( var i=0; i < 5; i++ )
       text += possible.charAt(Math.floor(Math.random() * possible.length));

   if($('#'+text).length>0) {
       return bullseye_makeid();
   }
   return text;
}
function generate_unique_string_from_input(input) {
    var str = input.prop('tagName').toLowerCase();
    if(input.attr('type') != undefined) {
        str = str+'_'+input.attr('type');
    }
    if(input.attr('id') != undefined) {
        str = str+'_'+input.attr('id');
    }
    if(input.attr('name') != undefined) {
        str = str+'_'+input.attr('name');
    }
    if(input.val() != undefined && typeof input.val() != 'array' && input.attr('type') != 'text') {
        str = str+'_'+input.val();
    }
    return str;
}
function validate_phone_number(no) {
    no = no.trim();
    var stripped = [];
    var parts = no.split("");
    jQuery.each(parts,function(key,value) {
        //alert(value);
        if(!isNaN(parseInt(value)) || value=="+") {
            stripped[stripped.length] = value;
        }
    });
    // console.log(stripped);
    //Lengths 10, 13, 11 (maar eerste karakter is +)
    if(no.length == 0 || stripped.length == 10 || stripped.length == 13) {
        return true;
    } else if(stripped.length == 12 && stripped[0] == "+") {
        return true;
    }
    return false;
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

    var json = JSON.stringify(cvalue);
    var esc = encodeURI(json);

    document.cookie = cname + "=" + esc + "; " + expires+"; path=/";
}
function getCookie(cname, default_value) {
    //var name = cname + "=";
    //var ca = document.cookie.split(';');
    //for(var i = 0; i <ca.length; i++) {
    //    var c = ca[i];
    //    while (c.charAt(0)==' ') {
    //        c = c.substring(1);
    //    }
    //    if (c.indexOf(name) == 0) {
    //        var json = c.substring(name.length,c.length);
    //
    //        var data = decodeURIObject(JSON.parse(json));
    //
    //        return data;
    //    }
    //}

    var arr = document.cookie.split(';');
    for(var i in arr) {
        var row = arr[i];
        var v = row.trim().substr(cname.length);
        if(v.substr(0,1) == '=') {
            var enc = v.substr(1);
            var json = decodeURI(enc);
            var data = JSON.parse(json);
            return data;
        }
    }

    if(default_value == undefined) {
        return "";
    }
    return default_value;
}
function deleteCookie(cname) {
    setCookie(cname,false,-1);
}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

function new_input_name(name,adding) {
    var p = name.split(']');
    if(p.length == 1) {
        return name+'_'+adding;
    }
    p[0] = p[0]+'_'+adding;
    return p.join(']');
}