jQuery(document).ready(function($) {
    $('input[type=password]').each(function() {
        var input = $(this);
        var h = input.outerHeight();
        var unique_id = bullseye_makeid();
        input.after('<div class="password-wrapper" id="'+unique_id+'"></div>');
        var wrapper = $('.password-wrapper#'+unique_id);
        wrapper.html(input);
        wrapper.append('<div class="view-icon fi-eye"></div>');
        var icon = wrapper.find('.view-icon');
        icon.css({
            'width': h,
            'height': h,
            'line-height': h+'px'
        });
        icon.click(function() {
            var input = $(this).parent().find('input');
            var type = input.attr('type');
            var new_type = 'text';
            if(type == 'text') {
                new_type = 'password';
                icon.removeClass('shown');
            } else {
                icon.addClass('shown');
            }
            input.attr('type',new_type).select().focus();
        });
    });
});