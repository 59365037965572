jQuery(document).ready(function($) {
    /**
     * Uses ajax-autocomplete method
     * @type {*|jQuery|HTMLElement}
     */
    var elm = $('*[data-squde-select-model]');
    if(elm.length > 0) {
        elm.SqudeSelectChildren();
    }

});
(function( $ ){
    $.fn.SqudeSelectModal = function(callback, dataFilter) {
        this.each(function() {
            var model = $(this).attr('data-squde-select-model');
            var url = '/ajax/model/'+model+'/search';
            $(this)
                .attr('data-ajax-autocomplete-url',url)
                .attr('data-ajax-autocomplete-search-key','value')
                .attr('data-ajax-autocomplete-params','field=name')
                .attr('data-ajax-autocomplete-compare','name')
                .attr('data-ajax-autocomplete-min-length',0)
                .ajaxAutoComplete(callback, dataFilter);
        });
        return this;
    };
})( jQuery );
(function( $ ){
    $.fn.SqudeSelectChildren = function(parent) {
        var xhr = false;
        var wait_for_run = false;
        var found_objects = {};
        var current_children_list = [];
        var current_custom = [];
        //var current_found_object_id = false;
        var elm = this;
        //console.log(elm.html());
        elm.SqudeSelectModal(function(data) {
            var child_container = $('*[data-squde-select-children-of]');
            var model = elm.attr('data-squde-select-model');
            if((child_container.length > 0 && child_container.attr('data-squde-select-children-of') == model) || parent != undefined) {
                if(xhr !== false) {
                    xhr.abort();
                }
                var callback = selected_submodels;
                var hidden_ID_input = $('input[name=selected_'+model.toLowerCase()+'_id]');
                if(hidden_ID_input.length == 0) {
                    if(elm.parents('form').length > 0) {
                        elm.parents('form').append('<input type="hidden" name="selected_'+model.toLowerCase()+'_id" value="" />');
                        hidden_ID_input = $('input[name=selected_'+model.toLowerCase()+'_id]');
                    }
                }
                var find_children = function (id) {
                    var attribute = 'skills';
                    if(child_container.attr('data-squde-select-children-attribute') != undefined ) {
                        attribute = child_container.attr('data-squde-select-children-attribute');
                    }
                    var onSuccess = function(object) {
                        //console.log(object);
                        found_objects[id.toString()] = object;
                        callback(found_objects[id.toString()]);
                    };
                    if(parent != undefined) {
                        onSuccess(parent);
                    } else {
                        xhr = $.ajax({
                            'url':'/ajax/model/'+model+'/get_attribute',
                            'data':{'id':id,'attribute':attribute},
                            'success':function(object) {
                                onSuccess(object);
                            }
                        });
                    }
                };
                if(elm.attr('type') == 'hidden' && elm.val() != undefined && elm.val().length > 0 && parseInt(elm.val()) > 0) {
                    find_children(elm.val());
                }
                else if(typeof data == 'string') {
                    callback(data);
                    hidden_ID_input.remove();
                } else if(found_objects[data.id.toString()] != undefined) {
                    hidden_ID_input.val(data.id);
                    callback(found_objects[data.id.toString()]);
                } else {
                    hidden_ID_input.val(data.id);
                    find_children(data.id);
                }

            }
        }).keydown(function() {
            var model = elm.attr('data-squde-select-model');
            var hidden_ID_input = $('input[name=selected_'+model.toLowerCase()+'_id]');
            hidden_ID_input.remove();
        });

        return this;

        function selected_submodels(data) {
            clearTimeout(wait_for_run);
            wait_for_run = setTimeout(function() {
                $('*[data-squde-select-children-of]').each(function() {
                    var child_container = $(this);

                    child_container.html('');
                    if(typeof data != 'string') {
                        current_children_list = data;
                        $.each(data.concat(current_custom),function(key,value) {
                            var optionValue = value.id;
                            child_container.append('<option value="'+optionValue+'" selected="selected">'+value.name+'</option>');
                        });
                    } else {
                        current_children_list = [];
                    }
                    var wrapper = child_container.next();
                    var ul = wrapper.find('ul');
                    if(!child_container.next().hasClass('select-children-wrapper')) {
                        var id = get_unique_id();
                        $('<div class="select-children-wrapper" id="'+id+'"><ul></ul></div>').insertAfter(child_container);
                        wrapper = $('.select-children-wrapper#'+id);
                        ul = wrapper.find('ul');

                        if(!child_container.is('[readonly]')) {
                            wrapper.append('<input type="text" id="new_'+id+'" class="add-icon" placeholder="Nieuwe vaardigheid..." /><br /><button style="margin-left:7.5px" class="button secondary" type="reset">Herstel vaardigheden</button>');
                        } else {
                            wrapper.attr('readonly','readonly');
                        }
                        var check_array = function(obj) {
                            var response = true;
                            var check_array = current_children_list.concat(current_custom);

                            $.each(check_array,function(key,value) {
                                if(typeof obj == 'string' && value.name.toLowerCase().trim() == obj.toLowerCase().trim()) {
                                    response = false;
                                } else if(typeof obj == 'object' && value.id == obj.id) {
                                    response = false;
                                }
                            });
                            return response;
                        };
                        var wrapper_input = wrapper.find('input#new_'+id);
                        wrapper_input
                            .attr('data-squde-select-model','Skill')
                            .SqudeSelectModal(function(data) {
                                //CALLBACK METHOD
                                var obj = data;
                                var add_to_list = true;
                                if(typeof data == 'string' && data.length > 0) {
                                    //Check if its not already in the custom array
                                    obj = {'id':get_unique_id(),'name':data};
                                    add_to_list = check_array(data);
                                    //$.each(current_custom,function(key,value) {
                                    //    if(value.name.toLowerCase().trim() == data.toLowerCase().trim()) {
                                    //        add_to_list = false;
                                    //    }
                                    //});
                                } else if(typeof data == 'string' && data.length == 0) {
                                    return false;
                                }
                                if(parseInt(obj.id) > 0) {
                                    //Check if it already is in the list
                                    add_to_list = check_array(obj);
                                    //$.each(current_children_list, function(key,value) {
                                    //    if(value.id == obj.id) {
                                    //        add_to_list = false;
                                    //        return false;
                                    //    }
                                    //});
                                }
                                wrapper.find('input').val('').focus();
                                if(add_to_list === true) {
                                    current_custom[current_custom.length] = obj;
                                    selected_submodels(current_children_list);
                                    child_container.trigger('change');
                                }
                                //console.log(data);
                            },function(data){
                                //FILTER METHOD
                                //Filters the already selected items and leaves them out of the results
                                var response = [];
                                $.each(data,function(key,value) {
                                    if(check_array(value)) {
                                        response[response.length] = value;
                                    }
                                });
                                return response;
                            });
                        wrapper.find('button[type=reset]').click(function(e) {
                            e.preventDefault();
                            current_custom = [];
                            selected_submodels(current_children_list);
                        });
                        child_container.change(function() {
                            if($(this).val() != null) {
                                var form = $(this).parents('form');

                                var name = child_container.attr('name').replace('[]','');
                                child_container.prop('disabled',true);
                                form.find("input[type=hidden][name^='"+name+"']").remove();
                                child_container.children('option').each(function() {
                                    var key = $(this).attr('value');
                                    var value = $(this).html();
                                    if(form.find("input[name='"+name+"["+key+"]']").length == 0) {
                                        form.append('<input name="'+name+'['+key+']" type="hidden" />');
                                    }
                                    form.find("input[name='"+name+"["+key+"]']").val(value);
                                });
                            }
                        });
                    }
                    child_container.trigger('change');
                    wrapper.find('.option:not(.custom)').remove();
                    if(typeof data != 'string') {
                        wrapper.find('.empty-option').hide();
                        $.each(data.concat(current_custom),function(key,value) {
                            var className = "option";
                            if(value.id == 0) {
                                className = "option custom";
                            }
                            var li_html = '<li class="'+className+'" data-id="'+value.id+'"><span>'+value.name+'</span></li>';
                            if(wrapper.find('.custom').length > 0) {
                                ul.find('.custom:first').before(li_html);
                            } else {
                                ul.append(li_html);
                            }
                            if(!child_container.is('[readonly]')) {
                                ul.find('li[data-id='+value.id+']').unbind('click').click(function() {
                                    var id = $(this).attr('data-id');
                                    $(this).remove();
                                    var select = wrapper.prev('select');
                                    select.find('option[value='+id+']').remove();
                                    var model = select.attr('data-squde-select-children-of').toLowerCase();
                                    var new_array = [];
                                    //console.log(found_objects[current_id]);
                                    //console.log(found_objects);
                                    $.each(current_children_list,function(found_key,found_value){
                                        if(found_value.id != id) {
                                            new_array[new_array.length] = found_value;
                                            //delete found_objects[current_id];
                                        }
                                    });
                                    current_children_list = new_array;

                                    new_array = [];
                                    $.each(current_custom, function(found_key,found_value) {
                                        if(found_value.id != id) {
                                            new_array[new_array.length] = found_value;
                                        }
                                    });
                                    current_custom = new_array;
                                    //console.log(found_objects[current_id]);


                                    selected_submodels(current_children_list);

                                });
                            }
                        });
                    } else {
                        wrapper.find('.empty-option').show();
                    }
                });
                //var child_container = $('*[data-squde-select-children-of]');
            },100);
        }
    }
})( jQuery );