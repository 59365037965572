jQuery(function($) {
    /**
     * Logo interactions
     */
    $('.logo').each(function() {
        $(this).html(' '+
            '<div class="logo-foreground"></div>'+
            '<div class="logo-pupil-wrapper">'+
            '<div class="logo-pupil"></div>'+
            '</div>'+
            '<div class="logo-background"></div>');
    });
    var close = false;
    $(window).mousemove(function(e){
        if(window.mobilecheck() === true) {
            return;
        }
        if($ == undefined)
        {
            var $ = jQuery.noConflict();
        }
        var w = $(window).width();
        var h = $(window).height();
        var x = e.clientX;
        var y = e.clientY;
        var wP = x/w;

        $('.logo.small').each(function() {

            var logo = $(this);

            var pupil = logo.find('.logo-pupil');

            var closeness_x = 0;
            var closeness_y = 0;

            //X
            var l = 0;
            $(function() {
                var c = logo.position().left + logo.width()/2;
                var diff = x-c;
                var longest_side = w-c;

                var p = diff / longest_side;
                closeness_x = p * 100;
                l = 15 * p + 50;
            });

            //Y
            var t = 0;
            $(function() {
                var c = logo.position().top + logo.height()/2;
                var diff = y - c;
                var longest_side = h-c;

                var p = diff / longest_side;
                closeness_y = p * 100;
                t = 20 * p + 50;

                //Max = 70
            });
            if(closeness_x < 10 && closeness_y < 10) {
                if(close == false) {
                    close = true;
                    pupil.animate({
                        'left':'50%',
                        'top':'50%'
                    },500);
                }
            } else {
                close = false;
                pupil.stop(true,false).css({
                    'left':l+'%',
                    'top':t+'%'
                });
            }
        });
    });
    $(document).ready(function() {
        $(window).trigger('mousemove');
    });
});
jQuery(function($) {
    $('a.hamburger-button').click(function(e) {
        //console.log('a');
        e.preventDefault();
        var hb = $(this).find('.hamburger-wrapper');
        var mm = $('.mobile-menu');
        if(hb.hasClass('close')) {
            //Close menu
            hb.removeClass('close');
            mm.removeClass('shown');
            setTimeout(function() {
                mm.hide();
            },500);
        } else {
            //Open menu
            hb.addClass('close');
            if(mm.length == 0) {
                //Add mobile menu to screen
                $('body').prepend(
                    '<div class="mobile-menu">' +
                    '<div class="row">' +
                    '<div class="columns small-11 medium-8">' +
                    '<div class="contents"></div>' +
                    '</div>' +
                    '</div>' +
                    '</div>');
                mm = $('.mobile-menu:first');
                var contents = $('.top-bar-contents').find('ul.horizontal-menu').clone();
                contents.attr('class','');
                mm.find('.contents').html(contents);
                mm.find('li.has-sub > a').click(function(e){
                    e.preventDefault();
                    var li = $(this).parent();
                    var ul = li.children('ul');
                    var h = 0;
                    if(li.hasClass('open')) {
                        li.removeClass('open');
                    } else {
                        li.addClass('open');
                        h = ul.children('div').height();
                    }
                    ul.height(h);
                });
                mm.find('li.has-sub ul').each(function() {
                    var ul = $(this);
                    ul.html("<div>"+ul.html()+"</div>");
                });
            } else {
                mm.show();
            }
            setTimeout(function() {
                mm.addClass('shown');
            },250);
        }
    });
});

if(jQuery('body').hasClass('home')) {
    jQuery(function($) {
        /**
         * Menu display
         */
        var going_away = false;
        var showing = false;
        $(window).scroll(function() {
            if($ == undefined)
            {
                var $ = jQuery.noConflict();
            }
            var t = $('body').scrollTop()+$('html').scrollTop();
            //$(function() {
            //    /**
            //     * Show menu bar when lower than big-image
            //     */
            //    var bg = $('.top-bar-background');
            //    var logo = $('.logo.small');
            //    var wH = $('.top-bar-wrapper').height();
            //    var left_wrapper = $('.top-bar-left-wrapper');
            //    var right_wrapper = $('.top-bar-right-wrapper');
            //    if(t > wH) {
            //        if(showing == false) {
            //            showing = true;
            //            going_away = false;
            //            var h = wH + wH*0.1;
            //            bg.stop(true,false).animate({'height':h,'opacity':1},500,function() {
            //                showing = false;
            //            });
            //            logo.stop(true,false).fadeIn(500);
            //            /*
            //            left_wrapper.removeClass('hide-for-large');
            //            right_wrapper.removeClass('large-12').addClass('large-10');
            //            */
            //        }
            //    }
            //    else {
            //        if(going_away == false) {
            //            going_away = true;
            //            showing = false;
            //            bg.stop(true,false).animate({'height':0,'opacity':0},500,function() {
            //                going_away = false;
            //            });
            //            logo.stop(true,false).fadeOut(500);
            //            /*
            //            left_wrapper.addClass('hide-for-large');
            //            right_wrapper.addClass('large-12').removeClass('large-10');
            //            */
            //        }
            //    }
            //});

            $(function() {
                /**
                 * Parallax scroll the content over the big-images
                 */
                $('.big-image').each(function() {
                    var this_t = t-($(this).offset().top);
                    var big_image = $(this);
                    var h = big_image.height();
                    if(this_t < h) {
                        var p = this_t/h;
                        //Jos bedacht
                        var divider = 4;
                        //David bedacht
                        divider = 20;
                        //Jos en Robin bedacht
                        divider = 8;
                        var max_margin = h/divider;
                        var m = p * max_margin*-1;
                        var table = big_image.find('.table').find('.row:first');
                        //table.css({
                        //    'margin-top':m
                        //});
                        table.css({
                            'transform':'translate(0,'+m+'px)',
                            'transition':'transform 0.25s ease-out'
                        })
                    }
                });
                //var h = $(window).height();
                //if(t < h) {
                //    var p = t/h;
                //    var max_margin = h/4;
                //    var m = p * max_margin*-1;
                //    var table = $('.big-image').find('.table').find('.row:first');
                //    table.css({
                //        'margin-top':m
                //    });
                //}
            });
        });
    });
    jQuery(function($) {
        //Form actions
        $('form.search_guy, form.search_job').submit(function(e){
            e.preventDefault();
            var form = $(this);
            var url_to_go_to = form.attr('action');
            //var area = $(this).find('input[name=area]').val();
            //if(area.length > 0) {
            //    url_to_go_to = url_to_go_to+'#filters[postalcode]='+area;
            //}
            window.location.href = url_to_go_to;
        });
    });
}
jQuery(window).scroll(function() {
    if($ == undefined)
    {
        var $ = jQuery.noConflict();
    }
    $(function() {
        /**
         * If in viewport, animate the count_ups
         */
        //var speed = 2500;
        //$('.count_it_up:in-viewport').each(function(){
        //    var count_container = $(this);
        //    count_container.removeClass('count_it_up');
        //    var total = count_container.html();
        //    total = parseInt(total.replace('.','').replace(',','.'));
        //
        //    $({countNum: 0}).animate({countNum: total}, {
        //        duration: speed,
        //        specialEasing:'easeInOutBack',
        //        step: function() {
        //            count_container.text(this.countNum.niceNumber(0));
        //        },
        //        complete: function() {
        //            count_container.text(this.countNum.niceNumber(0));
        //        }
        //    });
        //
        //
        //});
    });
}).trigger('scroll');

jQuery(document).ready(function($) {
   $('.labels-are-placeholders').placeholderLabels();
    $('.rating').ratingStars();
    $('.masonry-content').imagesLoaded(function(){
        $('.masonry-content').masonry({
            itemSelector: '.grid-item',
            // use element for option
            /*
            columnWidth: '.grid-sizer',
            percentPosition: true
            */
        });
    });
    jQuery(document).foundation();

    //'data-ajax-autocomplete-params'=>'field=identifier','data-ajax-autocomplete-compare'=>'identifier','data-ajax-autocomplete-min-length'=>0
});


Number.prototype.niceNumber = function(c, d, t){
    /**
     * c = decimals behind comma
     * d = decimal_separator
     * t = thousand_separator
     * @type {Number}
     */
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "," : d,
        t = t == undefined ? "." : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

window.mobilecheck = function() {
    var isMobile = false;
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;
    return isMobile;
}