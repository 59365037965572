jQuery(document).ready(function($) {
    var body = $('body');
    if(body.hasClass('is-logged-in')) {

        if(body.hasClass('add_job')) {
            var is_submitting = false;

            //var str = '{"name":"Testopdracht 3","description":"sdfds","city":"Swalmen","project_type_string":"Aanbouw / opbouw","user_id":1,"province":"Limburg","province_code":"LI","lat":51.2295456,"lng":6.046671,"job_status_id":1,"updated_at":"2017-04-10 10:25:25","created_at":{"date":"2017-04-10 10:25:25.000000","timezone_type":3,"timezone":"Europe/Amsterdam"},"id":42,"slug":"testopdracht-3","profession_names":["Asbestsaneerder"],"location_string":"Swalmen, LI","create_date":"10-04-2017","status_name":"concept","url":"http://localhost:8000/opdrachten/particulier/testopdracht-3","picture":"","status":{"id":1,"name":"concept","created_at":"2016-05-17 16:13:19","updated_at":"2016-05-17 16:13:19"},"files":[]}';
            //setCookie("newJob", JSON.parse(str), 1);
            //alert(getCookie("newJob",null));



            //deleteAllCookies();

            $('form.add_job_form').bind('submit.jobSubmit', function(e) {
                //In order to set the new job cookie, first try to submit. If it gives you errors, unbind the submit and resend it, so that they can be showed
                //If there are no errors, set a cookie and move to the job
                e.preventDefault();


                if(!is_submitting) {
                    var form = $(this);
                    var url = form.attr('action');
                    is_submitting = true;

                    $.ajax({
                        url: url,
                        dataType: 'json',
                        type: 'POST',
                        data: form.serializeJSON(),
                        success: function(job) {
                            setCookie("newJob",job,1);
                            setTimeout(function() {
                                window.location.href = job.url;
                            },200);
                        },
                        error: function(a,b,c) {
                            form.unbind('submit.jobSubmit').submit();
                        }
                    })
                }
            });
        }
        else if(body.hasClass('show_job')) {
            var job = getCookie("newJob", false);
            if(job != false) {
                if(job.url.split('/')[job.url.split('/').length - 1] == window.location.toString().split('/')[window.location.toString().split('/').length - 1]) {
                    logo_popup("Je klus is geplaatst!",'' +
                        '<p class="large-text text-left">Je hebt je opdracht zonder problemen geplaatst! We hopen dat hij snel geklaard wordt door één van onze vakmannen.</p>' +
                        '<p class="large-text text-left">Deel \'m op Facebook!</p>' +
                        '<p class="large-text text-left"><div class="fb-share-button" data-href="'+job.url+'" data-layout="button" data-size="large" data-mobile-iframe="true"><a class="fb-xfbml-parse-ignore" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(job.url)+'">Delen</a></div></p>' +
                        '<br /><a class="button">Sluiten</a>',function() {
                        $('a.button').click(function(e) {
                            e.preventDefault();
                            close_logo_popup();
                        },function() {
                            deleteCookie("newJob");
                        });
                    });
                }
            }
        }
    }
});
function complete_job(id) {
    var $ = jQuery.noConflict();

    jQuery.squdePopup('/ajax/jobs/complete/'+id);


    //$.get('/ajax/jobs/complete/'+id,function(html) {
    //    $('body').append('<div class="complete-job-wrapper"></div>');
    //    $('.complete-job-wrapper').html(html);
    //    $('.complete-job-scroller-wrapper').scrollLeft(0);
    //    $('.complete-job-lightbox-background').click(function() {
    //        close_complete_job();
    //    });
    //
    //    $(window).bind('resize.jobCompleteLightbox',function() {
    //        var wrapper = $('.complete-job-scroller-wrapper');
    //        var active = wrapper.find('.active');
    //        if(active.length > 0 && active.index() > 0) {
    //            var offset = wrapper.width() * active.index();
    //            wrapper.scrollLeft(offset);
    //        }
    //    });
    //});

    return false;
}

//function close_complete_job() {
//    var $ = jQuery.noConflict();
//    $('.complete-job-wrapper').fadeOut(500,function() {
//        $(this).remove();
//    });
//    $('*').unbind( ".jobCompleteLightbox" );
//    $(document).unbind( ".jobCompleteLightbox" );
//    $(window).unbind( ".jobCompleteLightbox" );
//    return false;
//}
//function next_complete_job_page() {
//    scroll_complete_job_page('r');
//    return false;
//}
//function prev_complete_job_page() {
//    scroll_complete_job_page('l');
//    return false;
//}
//function scroll_complete_job_page(dir) {
//    var $ = jQuery.noConflict();
//    var c = $('.complete-job-scroller-wrapper');
//    var w = $('.complete-job-scroller-container');
//    var a = w.children('.active');
//    var t = false;
//    if(dir == 'r') {
//        t = a.next();
//    } else {
//        t = a.prev();
//    }
//    if(t.length == 0) {
//        return false;
//    }
//    var offset = t.outerWidth() * t.index();
//    a.removeClass('active');
//    t.addClass('active');
//    var perc = Math.round((t.index()+1) / parseInt(c.attr('data-contains')) * 100);
//    $('.complete-job-progress-wrapper').attr('data-progress',perc);
//    c.animate({'scrollLeft':offset});
//}