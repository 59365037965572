function view_project(id) {
    var $ = jQuery.noConflict();
    var what_input = $('body').attr('data-whatinput');
    $.get('/ajax/projects/'+id,function(html) {
        $('body').append('<div class="project-view-wrapper"></div>');
        $('.project-view-wrapper').html(html);
        $('.project-view-wrapper').find('.slides-wrapper').animate({'scrollLeft':0},50);
        if(what_input == 'touch') {
            $('.project-view-wrapper').find('.slides-buttons').remove();
            $('.project-view-wrapper').find('.slides-wrapper').addClass('touch');
        }
        $(document).bind( "keydown.projectLightbox", function(e) {
            if(e.keyCode == 37) {
                slide_project_picture('l');
            } else if(e.keyCode == 39) {
                slide_project_picture('r');
            }
        });
        $(window).bind('resize.projectLightbox',function() {
            var wrapper = $('.project-view-wrapper').find('.slides-wrapper');
            var active = wrapper.find('.active');
            if(active.length > 0 && active.index() > 0) {
                var offset = wrapper.width() * active.index();
                wrapper.scrollLeft(offset);
            }
        });
        $('.project-lightbox-background').click(function() {
            close_project();
        });
    });
    return false;
}
function close_project() {
    var $ = jQuery.noConflict();
    $('.project-view-wrapper').fadeOut(500,function() {
        $(this).remove();
    });
    $('*').unbind( ".projectLightbox" );
    $(document).unbind( ".projectLightbox" );
    $(window).unbind( ".projectLightbox" );
    return false;
}
function slide_project_picture(direction) {
    var $ = jQuery.noConflict();
    var wrapper = $('.project-view-wrapper').find('.slides-wrapper');
    var active = wrapper.find('.slide:first');
    if(wrapper.find('.slide.active').length == 0) {
        active.addClass('active');
    } else {
        active = wrapper.find('.slide.active');
    }
    var next = active.next('.slide');
    if(direction == 'l') {
        next = active.prev('.slide');
    }
    if(next.length == 0) {
        return false;
    }
    var offset = wrapper.width() * next.index();
    active.removeClass('active');
    next.addClass('active');
    wrapper.stop(true,false).animate({'scrollLeft':offset});
}
jQuery(document).ready(function($) {
    //This code will be used when editing a project
    if($('body').hasClass('edit_project')) {
        var deleted = 0;
        $('.saved-picture').bind('option-delete',function() {
            deleted++;
            var pic = $(this).attr('data-id');
            var form = $(this).parents('form');
            form.append('<input type="hidden" name="delete_pictures[]" value="'+pic+'" />');
            $(this).parent().remove();
            $('.image-upload-wrapper').show();
            $('.image-upload.multiple').attr('data-max-files',deleted).trigger('max-files-changed');
        });
    }
});