jQuery(document).ready(function($) {
    if($('.edit-form-wrapper').length > 0) {
        var eF = $('.edit-form-wrapper');
        eF.find('a.open-editor').click(function(e) {
            e.preventDefault();
            var row = $(this).parents('.row');
            row.addClass('is-editing');
            //alert(row.find('input:first').parent().html());
            row.find(':input:not([type=hidden]):first').select().focus();
        });
        eF.find('.cancel-edit').click(function(e) {
            e.preventDefault();
            var row = $(this).parents('.row');
            row.find(':input').blur();
            row.removeClass('is-editing');
        });
    }
});