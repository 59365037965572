(function( $ ){
    $.fn.hiddenOverflow = function() {
        this.unbind('open').bind('open',function() {
            var elm = $(this);
            var child = elm.find('.hidden-by-overflow:first');
            var last_sub_child = child.children(':last');
            if(last_sub_child.attr('class') != 'clearfix') {
                child.append('<div class="clearfix"></div>');
            }
            var toHeight = child.outerHeight(true);
            child.find(':input').removeAttr('disabled');
            //Disable the children inputs that are in another wrapper that is closed
            child.find('.hidden-by-overflow-wrapper').not('.open').find(':input').attr('disabled','disabled');
            elm.height(toHeight).addClass('open');
        });
        this.unbind('close').bind('close',function() {
            var elm = $(this);
            var child = elm.find('.hidden-by-overflow:first');
            child.find(':input').attr('disabled','disabled');
            elm.height(0).removeClass('open');
        });
        return this;
    };
})( jQuery );
jQuery(document).ready(function($) {
    $('.hidden-by-overflow-wrapper').hiddenOverflow();
});