jQuery(document).ready(function($) {
    var found_promotion_item = getCookie('chosen-promotion-item');
    //chosen_a_phone("bv6000s");
    if(found_promotion_item != undefined && found_promotion_item.length > 0) {
        chosen_a_phone(found_promotion_item);
        if($('input[name=present_choice]').length > 0) {
            var input = $('input[name=present_choice][value='+found_promotion_item+']');
            //input.attr('checked','checked');
            input.prev().trigger('click');

            var w = $('.promotion-side-wrapper');

            var complete_checker = setInterval(function() {
                var SiC = $('.profile-form-wrapper').find('.show-if-complete');
                if(SiC.hasClass('show')) {
                    update_progress(Math.floor(100 / 6 * 2));
                    w.find('li#company_info').addClass('done');
                    clearInterval(complete_checker);
                }
            },10);

            var skills_checker = setInterval(function() {
                var skills_select = $('select#skills');
                if(skills_select.val() != null && skills_select.val().length > 0) {
                    update_progress(Math.floor(100 / 6 * 3));
                    w.find('li#one_skill').addClass('done');
                    clearInterval(skills_checker);
                }
            },10);

            var picture_checker = setInterval(function() {
                var picture_field = $('input[name=file_path]');
                if(picture_field.length > 0) {
                    update_progress(Math.floor(100 / 6 * 4));
                    w.find('li#pic').addClass('done');
                    clearInterval(picture_checker);
                }
            },10);
        }
    }
});
function chosen_a_phone(v) {
    var $ = jQuery.noConflict();
    if(v == undefined) {
        v = $('input[name=present_choice]:checked').val();
        setCookie('chosen-promotion-item', v, 0.5);
    }
    var w = $('.promotion-side-wrapper');
    w.removeClass('hidden');
    var ul = w.find('ul.options');
    ul.find('li').not('#option-'+v).hide();
    ul.find('li#option-'+v).show();
    update_progress();

    w.unbind('click').click(function() {
        if($(this).hasClass('open')) {
            $(this).removeClass('open');
        } else {
            $(this).addClass('open');
        }
    });

}
function update_progress(new_percentage) {
    var $ = jQuery.noConflict();
    var w = $('.promotion-side-wrapper');
    var percentage_holder = w.find('span.percentage');
    if(new_percentage == undefined) {
        new_percentage = parseInt(percentage_holder.attr('data-actual-percentage'));
    }
    if(new_percentage == 100) {
        setCookie("chosen-promotion-item", null, -1);
    }
    $({countNum: percentage_holder.html()}).animate({countNum: new_percentage}, {
        duration: 2000,
        step: function() {
            percentage_holder.html(Math.floor(this.countNum));
        },
        complete: function() {
            percentage_holder.html(new_percentage);
        }
    });
}
function close_promotion_info() {
    var $ = jQuery.noConflict();
    setCookie("chosen-promotion-item", null, -1);
    $('.promotion-side-wrapper').remove();
}