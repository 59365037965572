var last_scroll_screen_position = 0;
jQuery(document).ready(function($) {
    var scroll_screen_wrapper = $('.scroll-screen-wrapper');
    if(scroll_screen_wrapper.length > 0) {
        go_to_next_scroll_page();

        //Disable tabs that destroy the wizard
        scroll_screen_wrapper.find(':input').blur(function(e) {
            var last_input_in_screen = $(this).parents('.scroll-screen').find(':input:last');
            var id_1 = generate_unique_string_from_input(last_input_in_screen);
            var id_2 = generate_unique_string_from_input($(this));
            if(id_1 == id_2) {
                scroll_screen_wrapper.animate({'scrollLeft':last_scroll_screen_position},100,function() {
                    $(document.activeElement).blur();
                });
            }
        });
        scroll_screen_wrapper.find(':input').focus(function(e) {
            if(!$(this).is(':visible')) {
                $(this).blur();
            }
        });

        $(window).bind('resize',function() {
            var wrapper = $('.scroll-screen-wrapper');
            var active = wrapper.find('.active');
            if(active.length > 0 && active.index() > 0) {
                var offset = wrapper.width() * active.index();
                wrapper.scrollLeft(offset);
            }
        });
    }
});
function go_to_next_scroll_page() {
    var $ = jQuery.noConflict();
    var scroll_screen_wrapper = $('.scroll-screen-wrapper');
    var container = scroll_screen_wrapper.children('.scroll-screen-container');
    var active = scroll_screen_wrapper.find('.scroll-screen.active');
    var current = false;
    var do_next_one = true;
    if(active.length == 0) {
        active = scroll_screen_wrapper.find('.scroll-screen#screen-1');
        active.addClass('active');
        do_next_one = false;
    } else {
        current = active;
    }
    //active.show();
    var if_validation_complete = function() {
        if(do_next_one === true) {
            var n = active.next();
            if(n != undefined) {
                active.removeClass('active');
                //console.log(n.trigger('validate'));
                n.addClass('active');
                active = n;
            }
        }
        var step_percentage = parseInt(active.width() / container.width() * 100);
        var current_progress = step_percentage*(active.index()+1);
        if(current_progress > 98) {
            current_progress = 100;
        }
        setTimeout(function() {
            $('.scroll-screen-progress-wrapper').attr('data-progress',current_progress);
        },250);
        var left = active.width()*active.index();
        //var h = active.height();
        //container.children('.scroll-screen:not(.active)').hide();
        scroll_screen_wrapper.animate({'scrollLeft':left},function() {
            last_scroll_screen_position = left;
            $('body, html').animate({'scrollTop':0});
            active.trigger('on-show');
        });
    };


    if(current !== false && current.attr('data-validation-function') != undefined) {
        //DIT KAN MIS GAAN MET OBSCURE
        eval(current.attr('data-validation-function').replace(')',',if_validation_complete)'));
    } else {
        if_validation_complete();
    }
}
//function recalculate_height() {
//    var $ = jQuery.noConflict();
//    var scroll_screen_wrapper = $('.scroll-screen-wrapper');
//    var container = scroll_screen_wrapper.children('.scroll-screen-container');
//    var active = scroll_screen_wrapper.find('.scroll-screen.active');
//    var h = active.height();
//    scroll_screen_wrapper.stop(true,true).animate({'height':h});
//}