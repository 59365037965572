jQuery(document).ready(function($){
    if($('.search-screen-wrapper').length > 0) {
        $('.result-wrapper').hide();

        $( document ).ajaxStart(function() {
            if($('.results-container').parent().children('.busy-loading').length == 0) {
                $('.results-container').after('<div class="busy-loading"><div class="row"><div class="column text-center"><p>Bezig met laden...</p></div></div></div>');
            }
            $('.busy-loading').addClass('show');
        });
        $( document ).ajaxComplete(function() {
            $('.busy-loading').removeClass('show');
        });

        $('.open-filters').click(function(e){
            e.preventDefault();
            var b = $(this);
            var open = true;
            if(b.attr('data-close-text') != undefined) {
                b.attr('data-open-text', b.html());
                b.html(b.attr('data-close-text'));
                b.removeAttr('data-close-text');
            } else {
                b.attr('data-close-text', b.html());
                b.html(b.attr('data-open-text'));
                b.removeAttr('data-open-text');
                open = false;
            }
            var f = $('.filters-and-results-wrapper');
            if(open === true) {
                f.addClass('open');
            } else {
                f.removeClass('open');
            }
        });

        $('.filter').each(function() {
            $(this).find('input.search-filters').keyup(function() {
                var v = $(this).val().toLowerCase();
                var f = $(this).parents('.filter');
                var ul = f.find('ul');
                ul.find('li').not('.show-if-empty').not('.show-if-more').each(function() {
                    var li = $(this);
                    var c = li.text().trim().toLowerCase();
                    var words = c.split(' ');
                    var match = false;
                    for(var i in words) {
                        var word = words[i];
                        var part = word.replace(/[^a-z.]/, "").substr(0, v.length);
                        if(part == v) {
                            match = true;
                            break;
                        }
                    }
                    if(match === true) {
                        li.removeClass('no-match-filter').addClass('match-filter');
                    } else {
                        li.removeClass('match-filter').addClass('no-match-filter').hide();
                    }
                });
                reset_filter_height(f, 4);
            }).trigger('keyup');
            $(this).find('a.show-more').click(function(e) {
                e.preventDefault();
                var a = $(this);
                var f = a.parents('.filter');
                var ul = f.find('ul');
                var open_text = "";
                var close_text = "";
                var action = "open";
                if(a.attr('data-close-text') != undefined) {
                    close_text = a.attr('data-close-text');
                    a.removeAttr('data-close-text');
                    open_text = a.html();
                    a.attr('data-open-text',open_text);
                    a.html(close_text);
                } else {
                    open_text = a.attr('data-open-text');
                    a.removeAttr('data-open-text');
                    close_text = a.html();
                    a.attr('data-close-text',close_text);
                    a.html(open_text);
                    action = "close";
                }

                if(action == "open") {
                    ul.addClass('opened');
                    reset_filter_height(f);
                } else {
                    ul.removeClass('opened');
                    reset_filter_height(f, 4);
                }
            });
        });

        $(':input[name^="filter"], :input[name^="order"]').change(function() {
            $(this).parents('form').submit();
        });
        //Special code for project-types
        var bound_professions = {};
        var wait_for_submit = false;
        //$(':input[name^="filters[project_type"]').unbind('change').change(function() {
        //    clearTimeout(wait_for_submit);
        //    $(':input[name^="filters[professions"]').removeAttr('checked');
        //    var data = getData($('form.filters-form'));
        //    var project_types = data.filters.project_types;
        //    $(project_types).each(function(index) {
        //        var project_type_id = project_types[index];
        //        var if_professions_found = function(ids) {
        //            $(ids).each(function(i) {
        //                var profession_id = ids[i];
        //                $('input#professions_'+profession_id).prop('checked',true);
        //            });
        //            wait_for_submit = setTimeout(function() {
        //                $('form.filters-form').submit();
        //            },250);
        //        };
        //        if(!bound_professions[project_type_id]) {
        //            $.iGetModel('ProjectType','get_attribute',{'id':project_type_id,'attribute':'professions'},function(professions) {
        //                var ids = [];
        //                $(professions).each(function(i) {
        //                    var profession = professions[i];
        //                    ids[ids.length] = profession['id'];
        //                });
        //                bound_professions[project_type_id.toString()] = ids;
        //                if_professions_found(ids);
        //            });
        //        } else {
        //            if_professions_found(bound_professions[project_type_id]);
        //        }
        //    });
        //});
        var other_form = $('form.filters-and-order');
        if(other_form.length > 0) {
            other_form.submit(function(e) {
                e.preventDefault();
                $('form.filters-form').submit();
            });
        }
        $('form.filters-form').submit(function(e) {
            e.preventDefault();
            var f = $(this);
            var data = getData(f);
            var filters = data.filters;
            var order = data.order;
            //Query string
            window.location.hash=$.param({'filters':filters,'order':order});
            $(this).trigger('filter_change', [filters, order]);
            get_chosen_filters(filters);
        });

        $('.remove-filter').click(function(e) {
            e.preventDefault();
            var key = $(this).find('input').val();
            var data = object_from_string(window.location.hash.substr(1));
            delete data['filters'][key];
            //console.log(data.filters);
            set_filters(data);
        });

        if(window.location.hash.substr(1).length > 0) {
            set_filters(object_from_string(window.location.hash.substr(1)));
        } else {
            set_filters();
        }

        $('input#postalcode').bind('focus.get_address',function() {
            var input = $(this);
            input.unbind('focus.get_address');
            if(navigator.geolocation && input.val().length == 0) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
                    $.get('/ajax/helper/get_address_from_coordinates',{args:[lat,lng]},function(data) {
                        for(var key in data.address_components) {
                            var row = data.address_components[key];
                            var arr = row.types;
                            for(var subkey in arr) {
                                if(arr[subkey] == "postal_code") {
                                    input.val(row.short_name.replace(' ',''));
                                    input.select().parents('form').submit();
                                    break;
                                }
                            }
                        }
                    });
                });
            }
        });
    }

    function getData(f) {
        return get_filters_data(f, other_form);
    }
});

function get_filters_data(f, other_form) {
    var $ = jQuery.noConflict();
    var data = f.serializeJSON();

    var filters = {};
    if(data.filters != undefined) {
        filters = data.filters;
    }
    var order = {};

    if(other_form.length > 0) {
        var other_data = other_form.serializeJSON();
        if(other_data.filters != undefined) {
            filters = $.extend(filters, other_data.filters);
        }
        if(other_data.order != undefined) {
            order = other_data.order;
        }
    }

    for(var key in filters) {
        var filter = filters[key];
        if(typeof filter == 'object') {
            var bool = true;
            for(var i in filter) {
                var v = filter[i];
                if(v != "on") {
                    bool = false;
                    break;
                }
            }
            if(bool === true) {
                //console.log('a');
                var new_value = [];
                for(var i in filter) {
                    new_value[new_value.length] = i;
                }
                filters[key] = new_value;
            }
        } else {
            if(filter == 0) {
                delete filters[key];
            }
        }
    }
    //console.log(filters);
    return {filters:filters,order:order};
}

function reset_filter_height(filter, max_count)
{
    var $ = jQuery.noConflict();
    var ul = filter.find('ul');
    var show_all = false;
    if(max_count == undefined) {
        show_all = true;
    }
    if(ul.hasClass('opened')) {
        show_all = true;
    }

    var result_count = 0;
    var more_found_filters = 0;
    ul.find('li.match-filter').each(function(index) {
        if(show_all === true || index <= max_count-1) {
            $(this).show();
        } else {
            $(this).hide();
            more_found_filters++;
        }
        result_count++;
    });
    if(result_count == 0) {
        ul.find('.show-if-empty').show();
    } else {
        ul.find('.show-if-empty').hide();
    }
    if(more_found_filters > 0 || show_all === true) {
        ul.find('.show-if-more').find('.other-filters-count').html(more_found_filters);
        if(more_found_filters == 1) {
            ul.find('.one-more').show();
            ul.find('.multi-more').hide();
        } else {
            ul.find('.one-more').hide();
            ul.find('.multi-more').show();
        }
        ul.find('.show-if-more').show();
    } else {
        ul.find('.show-if-more').hide();
    }

    var new_height = ul.height();
    ul.parent().height(new_height);
}
function set_filters(data) {
    var $ = jQuery.noConflict();
    //First, reset all
    $(':input[name^="filters\\["]').each(function(){
        var tagname = $(this).prop('tagName').toLowerCase();
        var type = '';
        if(tagname == 'input') {
            type = $(this).attr('type');
        } else {
            type = tagname;
        }
        if(type == 'checkbox') {
            $(this).removeAttr('checked');
        } else if(type == 'text') {
            $(this).val('');
        } else if(type == 'select') {
            $(this).val($(this).find('option:first').attr('value'));
        }
        //Special reset for rating
        if(type == 'hidden' && $(this).parents('.rating').length > 0) {
            var v = $(this).val();
            $(this).val(0);
            $(this).parents('.rating').removeClass('rating-'+v).addClass('rating-0');
        }
    });

    //Then, set all
    for(var type in data) {
        var row = data[type];
        for(var key in row) {
            var value = row[key];
            if(typeof value == 'string') {
                var input = $(':input[name="'+type+'\\['+key+'\\]"]');
                value = value.replace('+',' ');
                input.val(value);
                if(key == 'rating-value') {
                    input.parent().removeClass('rating-0').addClass('rating-'+value);
                }
            } else {
                for(var i in value) {
                    var v = value[i];
                    $(':input[name^="'+type+'\\['+key+'\\]"]#'+key+'_'+v).prop('checked','checked');
                }
            }
        }
    }
    $('form.filters-form').submit();
}
var chosen_filters_delay = false;
function get_chosen_filters(filters) {
    var $ = jQuery.noConflict();
    clearTimeout(chosen_filters_delay);
    chosen_filters_delay = setTimeout(function() {
        //Look for the titles with the filters
        var chosen_filters = {};
        for(var key in filters) {
            var input = $(':input[name^="filters\\['+key+'\\]"]');
            var title = '';
            if(input.parents('.filter').length > 0) {
                //It's in the left box
                title = input.parents('.filter').find('h3').text().trim().replace('?','');
            } else {
                title = $('label[for='+input.attr('id')+']').text().trim().replace(':','');
            }
            if(title.length > 0) {
                chosen_filters[key] = title;
            }
        }

        //Sort them
        var sorted_object = {};
        var titles = [];
        for(var key in chosen_filters) {
            titles[titles.length] = chosen_filters[key];
        }
        titles.sort();
        for(var i in titles) {
            var title = titles[i];
            for(key in chosen_filters) {
                if(title == chosen_filters[key]) {
                    sorted_object[key] = title;
                    break;
                }
            }
        }
        chosen_filters = sorted_object;

        //Got them, show them
        var w = $('.chosen-filters-wrapper');
        w.find('.remove-filter').not(':first').remove();
        var i = 0;
        for(var key in chosen_filters) {
            w.css({'visibility':'visible'});
            $('label[data-for=chosen-filters]').show();
            var str = chosen_filters[key];
            var chosen_filter_link = w.find('.remove-filter:first');
            if(i > 0) {
                chosen_filter_link = chosen_filter_link.clone(true,true);
                chosen_filter_link.appendTo(w.find('ul'));
            }
            chosen_filter_link.show();
            chosen_filter_link.find('input').val(key);
            chosen_filter_link.find('.filter-name').html(str);
            i++;
        }

        if(i == 0) {
            w.css({'visibility':'hidden'});
            $('label[data-for=chosen-filters]').hide();
        }
    },250);

}
function object_from_string(query){
    var setValue = function(root, path, value){
        if(path.length > 1){
            var dir = path.shift();
            if( typeof root[dir] == 'undefined' ){
                root[dir] = path[0] == '' ? [] : {};
            }

            arguments.callee(root[dir], path, value);
        }else{
            if( root instanceof Array ){
                root.push(value);
            }else{
                root[path] = value;
            }
        }
    };
    var nvp = query.split('&');
    var data = {};
    for( var i = 0 ; i < nvp.length ; i++ ){
        var pair = nvp[i].split('=');
        var name = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);

        var path = name.match(/(^[^\[]+)(\[.*\]$)?/);
        var first = path[1];
        if(path[2]){
            //case of 'array[level1]' || 'array[level1][level2]'
            path = path[2].match(/(?=\[(.*)\]$)/)[1].split('][')
        }else{
            //case of 'name'
            path = [];
        }
        path.unshift(first);

        setValue(data, path, value);
    }
    return data;
}
function update_result_count(results) {
    var $ = jQuery.noConflict();
    var c = $('.results-counter');

    c.find('.results-count').html(parseInt(results));
    if(results == 1) {
        c.find('span#singular-results').show();
        c.find('span#multiple-results').hide();
    } else {
        c.find('span#singular-results').hide();
        c.find('span#multiple-results').show();
    }
}
var xhr = false;
var page = 0;
var recheck = false;
function fetch_the_data(url, filters, order, write_data_callback, page) {
    var $ = jQuery.noConflict();
    if(xhr !== false) {
        xhr.abort();
    }
    if(page == undefined) {
        //Set the page to 1 of filters or order changed
        page = 1;
    }
    xhr = $.ajax({
        url:url,
        data:{filters:filters, order:order, page:page},
        success : function(data){
            //console.log(data);

            if(page == 1) {
                $('.result-wrapper').hide();
            }
            var response = data;
            if(data.response) {
                response = data.response;
            }
            if(response.error) {
                $('.data-error-wrapper[data-error="'+response.error+'"]').show();
                if(recheck === false) {
                    recheck = setInterval(function() {
                        fetch_the_data(url, filters, order, write_data_callback, page);
                    },5000);
                }
            } else if(response.length > 0) {
                if(recheck !== false) {
                    clearInterval(recheck);
                }
                $('.data-error-wrapper').hide();
                $('.no-data-wrapper').hide();
                for(var i in response) {
                    var row = response[i];


                    var html_row = $('.result-wrapper:first');
                    var key_number = parseInt(i) + ((parseInt(page) - 1) * 10);
                    if(key_number > 0) {
                        if(html_row.parent().find('.result-wrapper:eq('+key_number+')').length > 0) {
                            html_row = html_row.parent().find('.result-wrapper:eq('+key_number+')');
                        } else {
                            var clone = html_row.clone(true,true);
                            html_row.parent().append(clone);
                            html_row = $('.result-wrapper:last');
                        }
                    }
                    html_row.hide();
                    write_data_callback(row, html_row);
                    html_row.show();

                    if(response.length == 10 && i == response.length-1) {
                        //If its the last row and there's more coming, update when the row is in viewport
                        html_row.attr('data-list-page', page);
                        $(window).scroll({html_row: html_row,url:url,filters:filters,order:order,write_data_callback:write_data_callback},function(e) {
                            if(e.data.html_row.is(':in-viewport') && e.data.html_row.attr('data-list-page') != undefined) {
                                var current_page = parseInt(e.data.html_row.attr('data-list-page'));
                                e.data.html_row.removeAttr('data-list-page');


                                var data = get_filters_data($('form.filters-form'),$('form.filters-and-order'));
                                var filters = data.filters;

                                fetch_the_data(e.data.url, filters, e.data.order, e.data.write_data_callback, (current_page+1));
                            }
                        });
                    }
                }
            } else if(page == 1) {
                $('.no-data-wrapper').show();
            }
            var count = data.length;
            if(data.totalCount && data.totalCount > 0 && data.totalCount != undefined) {
                count = data.totalCount;
            }
            if(count == undefined) {
                count = 0;
            }
            update_result_count(count);
        }
    });
}
