(function( $ ) {
    var xhr = false;
    $.getKVKData = function (no, callback) {
        var send_data = {'data':no};
        if(isNaN(no)) {
            send_data['nan'] = true;
        }
        if(xhr != false) {
            xhr.abort();
        }

        xhr = $.ajax({
            url:'/ajax/helper/get_kvk_data',
            data:send_data,
            success:function(data) {
                callback(data);
            }
        });
        //$.get('/ajax/helper/get_kvk_data',send_data,function(data){
        //    callback(data);
        //});
    }
})( jQuery );