jQuery(document).ready(function($)
{
    var select_2_vars = function(placeholder, ajax_url, custom_options) {
        var default_options = {
            placeholder:placeholder,
            ajax: {
                url: ajax_url,
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        'value': params.term, // search term
                        page: params.page,
                        field: 'name'
                    };
                },
                processResults: function (data, params) {
                    var items = [];
                    for(var i in data) {
                        var row = data[i];
                        //console.log(row);
                        items[i] = {
                            'id' : row.id,
                            'text' : row.name
                        };
                        /*
                         items[i]['id'] = row.id;
                         items[i]['text'] = row.name;
                         */
                    }
                    //console.log(items);

                    return {
                        results: items,
                        pagination: {
                            more: (params.page * 30) < items.length
                        }
                    };
                    /*
                     // parse the results into the format expected by Select2
                     // since we are using custom formatting functions we do not need to
                     // alter the remote JSON data, except to indicate that infinite
                     // scrolling can be used
                     params.page = params.page || 1;

                     return {
                     results: data.items,
                     pagination: {
                     more: (params.page * 30) < data.total_count
                     }
                     };
                     */
                },
                cache: true
            },
            escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
            /*
             minimumInputLength: 1,
             templateResult: formatRepo, // omitted for brevity, see the source of this page
             templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
             */
            change: function() {
                alert('a');
            }
        };
        if(custom_options) {
            for(var key in custom_options) {
                default_options[key] = custom_options[key];
            }
        }
        return default_options;
    };

    var prefix = 'model-select-';
    var selector = $("*[class^='"+prefix+"']");
    if(selector.length > 0) {
        selector.each(function() {
            var elm = $(this);
            var c = elm.attr('class');
            c = c.split(' ');
            var m = false;
            for(var i in c) {
                var class_name = c[i];
                if(class_name.substr(0,prefix.length) == prefix) {
                    m = class_name.substr(prefix.length);
                    break;
                }
            }
            if(m !== false) {
                var ph = elm.attr('placeholder');
                var custom_options = {};
                $.each( elm[0].attributes, function( index, attr ) {
                    if(attr.name.substr(0,'data-select2-option-'.length) == 'data-select2-option-') {
                        var name = attr.name.replace('data-select2-option-','');
                        var value = attr.value;
                        if(value == "true") {
                            value = true;
                        } else if(value == "false") {
                            value = false;
                        }
                        custom_options[ name ] = value;
                    }
                } );
                elm.empty();
                elm.select2(select_2_vars(ph,'/ajax/model/'+m+'/search', custom_options));
                //elm.change(function() {
                //    $(this).find(':not([value="'+$(this).val()+'"])').remove();
                //});
            }
        });
    }
});