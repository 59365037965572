//jQuery(document).ready(function($) {
//    setTimeout(function() {
//        add_review_for(84);
//    },1000);
//});
(function( $ ) {
    /**
     * √ Sometimes the latest height is not updated with reviews
     * √ Screen size change height update needed
     *
     * @type {boolean}
     */
    var xhr = false;
    var prefix = "squde-popup-latest";
    $.squdePopup = function (url, data, callback, size) {

        if(typeof data == 'function' && callback == undefined) {
            callback = data;
            data = {};
        } else if(data == undefined) {
            data = {};
        }
        if(size == undefined) {
            size = "large";
        }

        if(xhr != false) {
            xhr.abort();
        }

        xhr = $.ajax({
            url:url,
            data:data,
            success:function(data) {
                //Place the popup
                var id = get_unique_id();
                $('body').append('<div id="'+id+'" class="'+size+' '+prefix+'-wrapper reveal" data-reveal data-close-on-click="true" data-animation-in="fade-in-down fast ease-in" data-animation-out="fade-out-up fast ease-in">' +
                    '<div class="'+prefix+'-html"></div>' +
                    '<a class="close-reveal-modal" aria-label="Close" style="">&#215;</a>' +
                    '</div>');
                var elm = $('#'+id);
                var html = elm.find('.'+prefix+'-html');

                //Fill the popup with the given screen
                html.html(data);

                //Check if the popup is scrollable, if so, enable scrolling
                if(elm.find('.scroll-screen-wrapper').length > 0) {
                    //Set the wrapper
                    var wrapper = elm.find('.scroll-screen-wrapper');

                    //Scroll left, just to be sure
                    wrapper.animate({'scrollLeft': 0}, 100);

                    //Count how much screens there are and save it for the css
                    wrapper.attr('data-screen-count', wrapper.find('.scroll-screen').length);

                    //Set get_active function
                    var get_active = function() {
                        var active = wrapper.find('.scroll-screen.active');
                        if(active.length == 0) {
                            wrapper.find('.scroll-screen:first').addClass('active');
                            active = wrapper.find('.scroll-screen.active:first');
                        }
                        return active;
                    }.bind(wrapper);

                    //Set the get_progress function
                    var get_progress = function() {
                        var active = get_active();
                        var i = active.index() + 1;
                        var t = parseInt(wrapper.attr('data-screen-count'));
                        return Math.round(i / t * 100);
                    }.bind(wrapper);

                    //Add a progress-bar
                    html.append('<div class="progress-bar"></div>');
                    var progress_bar = html.children('.progress-bar');
                    progress_bar.attr('data-progress', get_progress());

                    //Make a height update event that can be triggered from the outside
                    wrapper.bind('updateHeight', function(e, new_height) {
                        if(new_height == undefined) {
                            new_height = get_active().outerHeight(true);
                        }
                        $(this).height(new_height);
                    });

                    //Give the wrapper events that can be triggered from the outside
                    wrapper.bind('scrollToScreen', function(e, screen, callback) {
                        //Find the active screen
                        var active = get_active();

                        //Find the screen it should scroll to
                        var to_screen;
                        if(screen == 'next') {
                            to_screen = active.next();
                        } else if(screen == 'prev' || screen == 'previous') {
                            to_screen = active.prev();
                        } else if(!isNaN(screen)) {
                            to_screen = wrapper.find('.scroll-screen:eq('+screen+')');
                        }


                        if(to_screen.length > 0) {
                            //Calc heights
                            var from_height = active.outerHeight(true);
                            var to_height = to_screen.outerHeight(true);

                            if(from_height < to_height) {
                                //Set the height beforehand
                                wrapper.trigger('updateHeight', to_height);
                            }

                            //Scroll to the right screen
                            wrapper.animate({'scrollLeft': to_screen.outerWidth() * to_screen.index()}, function() {
                                //Trigger the new active screen
                                active.removeClass('active');
                                to_screen.addClass('active');

                                if(from_height > to_height) {
                                    //Set the height afterwards
                                    wrapper.trigger('updateHeight');
                                }

                                //Update the progress
                                progress_bar.attr('data-progress', get_progress());

                                //Trigger a callback if set
                                if(typeof callback == 'function') {
                                    callback();
                                }
                            });
                        }
                    });

                    //Find the active screen
                    var active = get_active();

                    //Add next / prev triggers to the buttons
                    wrapper.find('[data-next-button], [data-previous-button]').click(function(e) {
                        e.preventDefault();
                        var to;
                        if ($(this)[0].attributes['data-next-button'] != undefined) {
                            to = 'next';
                        } else {
                            to = 'previous';
                        }
                        wrapper.trigger('scrollToScreen', to);
                    });

                    //Change position upon resize
                    $(window).bind('resize.'+prefix,function() {
                        var active = get_active();
                        if(active.index() > 0) {
                            var offset = wrapper.width() * active.index();
                            wrapper.scrollLeft(offset);
                        }
                        wrapper.trigger('updateHeight');
                    });
                }

                //Check if the popup contains a form
                if(elm.find('form').length > 0) {
                    //Check if the form does not have an onsubmit method
                    var form = elm.find('form');
                    if(form.attr('onsubmit') == undefined && form.attr('data-no-ajax') == undefined) {
                        //Create own onsubmit
                        form.submit(function(e) {
                            e.preventDefault();
                            var url = form.attr('action');
                            if(url == undefined) {
                                url = "/";
                            }
                            var type = form.attr('method');
                            if(type == undefined) {
                                type = 'POST';
                            }
                            //Init the ajax
                            form.find(':input.error').removeClass('error');
                            form.find('small.error').remove();
                            $.ajax({
                                url: url,
                                data: form.serializeObject(),
                                type: type,
                                success: function(data) {
                                    // Create the has_event function
                                    var has_event = function(elm, event_name) {
                                        return $._data(elm[0],'events')[event_name] != undefined;
                                    };

                                    //If form has an ajax-success event, trigger it
                                    if(has_event(form, 'ajax-success')) {
                                        form.trigger('ajax-success', data);
                                    } else {
                                        //If not, reload the page
                                        window.location.reload();
                                    }
                                },
                                error: function(error) {
                                    if(error.responseJSON) {
                                        var scroll_index = -1;
                                        for (var key in error.responseJSON) {
                                            var error_str = error.responseJSON[key][0];
                                            form.find(':input').each(function() {
                                                var i = $(this);
                                                if(i.attr('name') == key) {
                                                    var id = i.attr('id');
                                                    if(id != undefined && id.length > 0 && form.find('label[for='+id+']').length > 0) {
                                                        var label = form.find('label[for='+id+']');
                                                        if(label.length > 0) {
                                                            label.append('<small class="error">'+error_str+'</small>');
                                                        }
                                                    } else {
                                                        $('<small class="error">'+error_str+'</small>').insertAfter(i);
                                                    }
                                                    i.addClass('error');
                                                    if(i.parents('.scroll-screen').length > 0) {
                                                        var index = i.parents('.scroll-screen').index();
                                                        if(index < scroll_index || scroll_index == -1) {
                                                            scroll_index = index;
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        if(scroll_index >= 0) {
                                            elm.find('.scroll-screen-wrapper').trigger('scrollToScreen', scroll_index);
                                        }
                                    } else {
                                        console.log(error);
                                    }
                                }
                            })
                        })
                    }
                }

                //Open the popup
                var popup = new Foundation.Reveal(elm);
                popup.open();

                //Add a close action
                elm.find('.close-reveal-modal').click(function() {
                    popup.close();
                }.bind(popup));

                //Run callback if needed
                if(typeof callback == 'function') {
                    callback(elm, popup);
                }
            },
            error: function() {
                bullseye_flash("Er is wat mis gegaan", "alert");
            }
        });
    }
})( jQuery );