jQuery(document).ready(function($) {

    var cookie_name = 'hasSeenBetaModal3';


    var form = $('form.beta-feedback-form');
    reset_form(form);
    form.submit(function(e){
        e.preventDefault();
        var form = $(this);
        var data = form.serialize();

        form.find('.error-str').hide();
        $.ajax({
            url: '/ajax/helper/submit_beta_feedback',
            data: data,
            type: 'POST',
            success: function(data) {
                reset_form(form);
                form.find('input[type=submit]').attr('value',"Bedankt!").attr('disabled','disabled');
            },
            error: function() {
                form.find('.error-str').show();
            }
        })
    });

    function reset_form(form) {
        //form.find('input[type=text]').val('');
        form.find('textarea').val('');
        form.find('input[type=submit]').removeAttr('disabled');
    }
    if(!is_beta()) {
        return;
    }
    var c = getCookie(cookie_name);


    if(c === true) {
        return;
    }
    if(!$('body').hasClass('home')) {
        return;
    }
    var html = get_beta_message(3);
    html = html +
        "<strong>Team Bullseye</strong></p>" +
        "<div class='text-center'><button class='confirm-button button primary'>Sluiten</button></div>";
    show_modal('Welkom tester!',html,false,{
        confirm: function(elm,data) {
            setCookie(cookie_name,true,36500);
            elm.close();
        }
    });
});
function get_beta_message(msg_id) {
    if(msg_id == 1) {
        return "<p><h3>Gefeliciteerd!</h3>" +
            "Je bent uitgenodigd om bij de allereerste echte groep testers van Bullseye.nl te gaan behoren!<br /><br />" +
            "<strong>Wat wil het zeggen een tester te zijn?</strong><br /> Heel simpel, tijdens de ontwikkeling van Bullseye.nl wordt er zoveel gebouwd dat het niet altijd makkelijk is om bij te houden of dingen niet met elkaar in conflict komen.<br />" +
            "Daarnaast willen we heel graag weten wat de doelgroep van het systeem vindt, en wat ze vinden dat verbeterpunten kunnen zijn." +
            "<br /><br /><strong>Hoe kun je doorgeven wat er mis is?</strong><br />" +
            "Je kunt op iedere pagina omlaag scrollen. Daar vind je een heel minimalistisch contactformulier dat rechtstreeks in verbinding staat met onze ontwikkelafdeling. Vul daar je gevonden fout in en je opmerking zal zo snel mogelijk worden opgepakt.<br /><br />" +
            "<strong>Wanneer is iets een fout?</strong><br />" +
            "Wanneer je via ons hebt begrepen dat iets zou moeten werken en het gaat mis (een scherm vol met fouten, of een knop die niet reageert), dan geef je het door. Hebben wij nog niet doorgegeven dat iets moet werken, dan is het waarschijnlijk geen fout, maar nog niet ontwikkeld." +
            "<br/><br />" +
            "We hopen dat jullie er een beetje uitkomen. Mocht het niet zo zijn, gebruik dan gerust het contactformulier onderaan de website om vragen te stellen, of neem telefonisch contact met ons op.<br /><br />" +
            "<h3>Wat kan er getest worden?</h3>" +
            "<p><em>Aanmelden als vakman + aanmaken profiel + zoeken naar profielen.</em></p>";
    }
    else if(msg_id == 2) {
        return "<p>Zoals je ziet is Bullseye totaal vernieuwd! Gebruik de beta-bevindingen-bak om ons te vertellen wat je ervan vind.</p>" +
            "<p>We zijn druk bezig geweest met een meer intuïtieve werking en we horen graag of we dat nu op het juiste spoor zijn.</p>" +
            "<p>Probeer bijvoorbeeld eens een profiel aan te maken in ons vernieuwde <a href='/register'>aanmeldsysteem</a>, of een <a href='/job/add'>opdracht te plaatsen</a>.</p>" +
            "<p>Ook al moet er hier en daar nog wat <em>finetuning</em> plaatsvinden, wilden wij jullie deze versie niet onthouden.</p>" +
            "<p>Alvast super bedankt voor jullie tests!</p>";
    }
    else if(msg_id == 3) {
        return "<p>Leuk dat je er weer bent!</p>" +
            "<p>Gaat er iets mis tijdens het testen? Gebruik het contactformulier onderaan iedere pagina om dit aan ons door te geven.</p>" +
            "<p>Alvast super bedankt voor jullie tests!</p>";
    }
}
function underConstructionMessage() {
    alert('Sorry!\nDit onderdeel is nog in ontwikkeling');
    return false;
}
function is_beta() {
    var p = window.location.toString().split('localhost');
    if(p.length == 2) {
        return true;
    }
    p = window.location.toString().split('beta.bullseye.nl');
    return p.length == 2;

}