jQuery(document).ready(function($) {
    if($('body').hasClass('jobs')) {
        $('form.filters-form').on('filter_change',function(e, filters, order) {
            //Execute the actual filtering
            //console.log({'filters':filters,'order':order});
            fetch_the_data('/ajax/jobs/search', filters, order, function(data, row) {
                //console.log(data);
                //Company name
                row.find('h3 a').html(data.name);

                //Url
                //console.log(data);
                row.find('h3 a').attr('href',data.url);

                ////Image
                row.find('img').attr('src',data.picture);
                row.find('img').parent().attr('href',data.url);

                //Professions
                if(data.profession_names) {
                    row.find('.professions-wrapper').html(data.profession_names.join(', '));
                    row.find('.professions-wrapper').show();
                } else {
                    row.find('.professions-wrapper').hide();
                }

                //Location info
                row.find('.location-info').html(data.location_string);

                //Views
                row.find('.views').html(data.views);

                //Views
                console.log(data);
                row.find('.create_date').html(data.create_date);

                //Done
                if(data.done == 0) {
                    row.find('.done-indicator').removeClass('shown');
                } else {
                    row.find('.done-indicator').addClass('shown');
                }
            });
        });
    }
});