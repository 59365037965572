var profile_searcher_ajax = false;
function load_profile_searcher(sender) {
    var $ = jQuery.noConflict();
    var elm = $(sender);
    var v = elm.val();
    var wrapper = elm.next();
    if(wrapper.length == 0) {
        elm.after('<div class="profile-results-wrapper"><ul></ul></div>');
        wrapper = elm.next();
    }
    var ul = wrapper.find('ul');
    var result_container_func = function(id, img, name, city) {
        return '<li data-id="'+id+'"><div class="left"><img src="'+img+'" /></div><div class="right"><b>'+name+'</b> ('+city+')</div><div class="clearfix"></div></li>';
    };
    var initial_ids = elm.attr('data-initial-profiles').split(',');
    ul.empty();
    var form = elm.parents('form');
    var input = form.find('input#profile-id');
    if(input.length == 0) {
        form.append('<input type="hidden" name="profile_id" id="profile-id" />');
        input = form.find('input#profile-id');
    }
    if(profile_searcher_ajax !== false) {
        profile_searcher_ajax.abort();
    }
    profile_searcher_ajax = $.ajax({
        url: '/ajax/profiles/nameSearch',
        data: {v:v, initial_ids: initial_ids},
        dataType: 'JSON',
        success: function(data) {
            data.forEach(function(profile) {
                ul.append(result_container_func(profile.id, profile.thumbnail, profile.company_name, profile.city));
                ul.find('li').click(function() {
                    var li = $(this);
                    var id = li.attr('data-id');
                    var form = li.parents('form');
                    var input = form.find('input#profile-id');
                    ul.find('li').removeClass('active');
                    input.val(id);
                    li.addClass('active');
                    ul.find('li:not(.active)').remove();
                });
            });
        }
    });
}
function clear_selected_profiles_and_submit(sender) {
    var $ = jQuery.noConflict();
    var elm = $(sender);
    var form = elm.parents('form');
    form.find('.profile-results-wrapper').remove();
    form.find('input#profile-id').remove();
    form.submit();
    return false;
}