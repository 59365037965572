(function ( $ ) {
    $.fn.isLoading = function(className) {
        this.each(function() {
            var elm = $(this);
            elm.attr('data-is-loading',1);
            if(className == undefined) {
                className = "";
            }
            elm.find('.loading-indicator-wrapper').remove();
            elm.append('<div class="loading-indicator-wrapper '+className+'"><div class="loading-indicator"></div></div>');
            var maxZ = Math.max.apply(null,
                $.map($('body *'), function(e,n) {
                    if ($(e).css('position') != 'static')
                        return parseInt($(e).css('z-index')) || 1;
                }));
            elm.find('.loading-indicator-wrapper').css({'line-height': elm.outerHeight()+'px', 'z-index': maxZ});
        });
        return this;
    };
    $.fn.doneLoading = function() {
        this.each(function() {
            var elm = $(this);
            elm.find('.loading-indicator-wrapper').fadeOut(500,function() {
                elm.removeAttr('data-is-loading');
                $(this).remove();
            });
        });
        return this;
    };
}( jQuery ));