jQuery(document).ready(function($) {
    if($('.sidebar-box.options-menu').length >0) {
        $(window).on('hashchange', function() {
            var hash = window.location.hash;
            if($('.sidebar-box.options-menu').find("a[href='"+hash+"']").length > 0) {
                var li = $('.sidebar-box.options-menu').find("a[href='"+hash+"']").parent();
                li.parent().children('li').removeClass('active');
                li.addClass('active');
                if($(hash+'_fields').length > 0) {
                    var show_element = $(hash+'_fields');
                    var tag_name = show_element.prop('tagName').toLowerCase();
                    show_element.parent().children(tag_name).hide();
                    show_element.show();

                    /**
                     * When a show_element contains a select2 element, and it's not initially shown, select2 gives it
                     * a 100px width. Remove that, when you see it.
                     */
                    if(show_element.find('.select2')) {
                        show_element.find('.select2').each(function(){
                            if($(this).width() == 100) {
                                $(this).width($(this).parent().width());
                            }
                        });
                    }
                }
            }
        }).trigger('hashchange');
    }
});