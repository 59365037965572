var original_placeholders = {};
jQuery(document).ready(function($) {
    var elms = $('*[data-ajax-autocomplete]');
    if(elms.length > 0) {
        elms.ajaxAutoComplete();
    }
});
(function( $ ){
    $.fn.ajaxAutoComplete = function(callback, dataFilter) {
        var blur_options_timeout = false;
        var xhr = false;
        var found_objects = {};
        var found_complete = [];
        this.each(function() {
            var input = $(this);
            if(typeof callback == 'function') {
                callback(input.val());
            }
            input.prop('autocomplete','off');
            var parent_z = parseInt(input.parent().css('z-index'));
            if(isNaN(parent_z)) {
                parent_z = 0;
            }
            var this_z = parseInt(input.css('z-index'));
            if(isNaN(this_z)) {
                this_z = 0;
            }
            if(this_z > parent_z) {
                parent_z = this_z;
            }
            var display_css = input.css('display');
            var container_position_css = input.parent().css('position');
            var h = input.outerHeight()+parseFloat(input.css('margin-top'))+parseFloat(input.css('margin-bottom'));
            var w = input.outerWidth()+parseFloat(input.css('margin-left'))+parseFloat(input.css('margin-right'));

            var top_offset = input.offset().top;
            //alert(display_css);
            var id = get_unique_id();
            $('<div id="'+id+'"></div>').insertBefore(input);
            var div = $('div#'+id);
            div.html(input);
            var new_top_offset = div.offset().top;
            //console.log(new_top_offset+' '+top_offset);
            var top = parseFloat(new_top_offset)-parseFloat(top_offset)-2;
            input.css({
                'position':'absolute',
                'left':0,
                'top':top,
                'background-color':'rgba(0,0,0,0)',
                'z-index':parent_z+3,
                //'width':w,
                'width':'100%',
            });
            var clone = input.clone(true,true);
            div.append(clone);
            clone.removeAttr('required');
            clone.prop('disabled',true);
            clone.attr('id','');
            clone.attr('name','');
            clone.unbind('keyup');
            clone.css({'z-index':parent_z+2});
            clone.val('');
            div.css({'position':'relative','height':h});
            $('body').append('<div class="autocomplete-option-list-wrapper" id="options-for-'+id+'"><ul></ul></div>');
            var autocomplete_list_wrapper = $('.autocomplete-option-list-wrapper#options-for-'+id);
            autocomplete_list_wrapper.css({
                //'top':input.offset().top+input.outerHeight(),
                //'left':input.position().left,
                'z-index':parent_z+50
            });
            if(display_css != 'block') {
                div.css({'display':display_css,'width':w});
            }
            if(container_position_css == 'relative') {
                div.parent().width(div.width());
                div.css({
                    'display':'inline-block',
                    'position':'absolute',
                });
                div.find('input').css({
                    'top':parseFloat(div.find('input:first').css('top'))-(input.height() / 2)+4
                });
            }
            //if(display_css != 'block')
            if(input.attr('placeholder') != undefined) {
                original_placeholders[generate_unique_string_from_input(input)] = input.attr('placeholder');
            }
            if(is_fixed(input)) {
                autocomplete_list_wrapper.css('position','fixed');
            }

            if(input.val() != null && input.val().length > 0) {
                clone.removeAttr('placeholder');
            }

            //This boolean makes sure an already set value will also trigger the selected method
            var hidden_check = false;

            input.bind('keyup focus',function(e) {
                if(e.keyCode && [38,40].indexOf(e.keyCode) > -1) {
                    //Do nothing on keyup with the arrow keys
                    return;
                }
                var id = $(this).parent().attr('id');
                var input = $(this);
                //var original_placeholder = input.original_pla
                if(input.val().length > 0) {
                    input.removeAttr('placeholder');
                } else {
                    var uid = generate_unique_string_from_input(input);
                    if(original_placeholders[uid]) {
                        var placeholder = original_placeholders[uid];
                        input.attr('placeholder',placeholder);
                    }
                }

                var list_wrapper = $('.autocomplete-option-list-wrapper#options-for-'+id);
                list_wrapper.offset({
                    'left':input.offset().left,
                    'top':input.offset().top + input.outerHeight()
                });
                var ul = list_wrapper.find('ul');
                list_wrapper.width($(this).outerWidth());
                var padding_left = $(this).css('padding-left');
                var padding_right = $(this).css('padding-right');
                resetAutoComplete($(this),0);
                var clone = $(this).next();
                var v = $(this).val();
                var compare = $(this).attr('data-ajax-autocomplete-compare');

                var url = input.attr('data-ajax-autocomplete-url');
                var params = {};
                if(input.attr('data-ajax-autocomplete-params') != undefined) {
                    params = getAutoCompleteParams(input);
                }
                if(input.attr('data-ajax-autocomplete-search-key') != undefined) {
                    params[input.attr('data-ajax-autocomplete-search-key')] = v;
                } else {
                    params.search_string = v;
                    params = {args:params};
                }
                var min_length = 2;
                if(input.attr('data-ajax-autocomplete-min-length') != undefined) {
                    min_length = input.attr('data-ajax-autocomplete-min-length');
                }
                var is_hidden_checking = false;
                if(hidden_check === true) {
                    is_hidden_checking = true;
                    hidden_check = false;
                }
                if(v.length < min_length) {
                    clone.removeAttr('placeholder');
                } else {
                    setTimeout(function(elm) {
                        if(elm.val().length < min_length) {
                            xhr.abort();
                            clone.removeAttr('placeholder');
                        }
                    }.bind(null, $(this)),250);
                    if(xhr !== false) {
                        xhr.abort();
                    }
                    xhr = $.ajax({
                        url:url,
                        data:params,
                        success:function(data) {
                            var id = input.parent().attr('id');
                            if(dataFilter != undefined && typeof dataFilter == 'function') {
                                data = dataFilter(data);
                            }
                            var complete = [data];
                            if(data[0] != undefined) {
                                complete = data;
                                data = data[0];
                            }
                            if(data.length == 0 || data[compare] == undefined || !data[compare]) {
                                delete found_objects[id];
                                resetAutoComplete($(this));
                                //if(typeof callback == 'function') {
                                //    callback(v);
                                //}
                                //console.log('data.length == 0 || data[compare] == undefined || !data[compare]');
                            } else {
                                var ret = data[compare].substr(v.length);
                                if(ret.length == 0 || parseInt(v) > 0 || v.length == 0 || (data[compare].substr(0, v.length).toLowerCase() != v.toLowerCase())) {
                                    clone.removeAttr('placeholder');
                                } else {
                                    clone.attr('placeholder',(v+''+ret));
                                }
                                found_objects[id] = data;
                                found_complete = complete;

                                //Show the options
                                if(input.is(':visible')) {
                                    if(is_hidden_checking === true) {
                                        found_objects[id] = complete[0];
                                        submitCompletion(input);
                                        is_hidden_checking = false;
                                    } else {
                                        $.each(complete,function(index,value) {
                                            //console.log(value);
                                            var idStr = '';
                                            if(value.id != undefined) {
                                                idStr = 'data-id="'+value.id+'"';
                                            }
                                            ul.append('<li '+idStr+'>'+value[compare]+'</li>');
                                        });
                                        var h = ul.outerHeight();
                                        if(h > ul.find('li:first').outerHeight()*5) {
                                            h = ul.find('li:first').outerHeight()*5;
                                        }
                                        list_wrapper.height(h);
                                        if(list_wrapper.is(':visible')) {
                                            list_wrapper.addClass('shown');
                                        }
                                        ul.find('li').css({
                                            'padding-left':padding_left,
                                            'padding-right':padding_right
                                        }).click(function() {
                                            clearTimeout(blur_options_timeout);
                                            var i = $(this).index();
                                            var id = $(this).parents('.autocomplete-option-list-wrapper').attr('id').replace('options-for-','');
                                            found_objects[id] = complete[i];
                                            //console.log(found_objects);
                                            submitCompletion(input);
                                        }).hover(function() {
                                            $(this).parent().find('.sort_of_selected').removeClass('sort_of_selected');
                                            var id = $(this).parents('.autocomplete-option-list-wrapper').attr('id').replace('options-for-','');
                                            found_objects[id] = found_complete[0];
                                        });
                                    }
                                }
                            }

                        }
                    });
                }
            }).keydown(function(e) {
                var id = $(this).parent().attr('id');
                if(e.keyCode == 13 && typeof found_objects[id] == 'object') {
                    //If enter is pressed AND the searched string == first found string, select it
                    var compare = $(this).attr('data-ajax-autocomplete-compare');
                    var val = $(this).val();
                    var found_val = found_objects[id][compare];
                    if(val.trim().toLowerCase() == found_val.trim().toLowerCase()) {
                        submitCompletion($(this));
                    }
                } else if(e.keyCode == 13 && (found_objects[id] == undefined || found_objects[id] == null)) {
                    //If enter is pressed and nothing has been found submit
                    submitCompletion($(this));

                } else if(e.keyCode == 9) {
                    e.preventDefault();
                    submitCompletion($(this));
                } else if([38,40].indexOf(e.keyCode) > -1) {
                    return moveSelection(id, e.keyCode == 38 ? 'prev' : 'next');
                } else {
                    clearTimeout(blur_options_timeout);
                    resetAutoComplete($(this),0);
                }
            });
            input.blur(function() {
                setTimeout(function() {
                    if(original_placeholders[generate_unique_string_from_input(input)]) {
                        var placeholder = original_placeholders[generate_unique_string_from_input(input)];
                        input.attr('placeholder',placeholder);
                    }
                },350);
                resetAutoComplete($(this));
                //
                //var id = $(this).parent().prop('id');
                //if(found_objects[id]) {
                //    if($(this).attr('data-ajax-autocomplete-compare') != undefined) {
                //        var compare = $(this).attr('data-ajax-autocomplete-compare');
                //        $(this).val(found_objects[id][compare]);
                //        $(this).trigger('ajax-autocomplete-complete',[found_objects[id]]);
                //    }
                //    if(typeof callback == 'function') {
                //        callback(found_objects[id]);
                //    }
                //} else if(typeof callback == 'function') {
                //    callback($(this).val());
                //}
            });
            if(input.val() != null && input.val().length > 0) {
                if(input.attr('data-ajax-autocomplete-run-on-init') == undefined || input.attr('data-ajax-autocomplete-run-on-init') == '1') {
                    hidden_check = true;
                    input.trigger('focus');
                }
            }
        });
        var saveWindowWidth = true;
        var savedWindowWidth;

        //set the initial window width
        if (saveWindowWidth = true){
            savedWindowWidth = window.innerWidth;
            saveWindowWidth = false;
        }
        $(window).resize(function() {
            //if the screen has resized then the window width variable will update
            windowWidth = window.innerWidth;


            //if the saved window width is still equal to the current window width do nothing
            if (savedWindowWidth == windowWidth){
                return;
            }


            //if saved window width not equal to the current window width do something
            if(savedWindowWidth != windowWidth) {
                // do something
                $(document.activeElement).blur();

                savedWindowWidth = windowWidth;
            }
        });
        function submitCompletion(input) {
            var id = input.parent().prop('id');
            if(found_objects[id]) {
                if(input.attr('data-ajax-autocomplete-compare') != undefined) {
                    var compare = input.attr('data-ajax-autocomplete-compare');
                    input.val(found_objects[id][compare]);
                    input.trigger('ajax-autocomplete-complete',[found_objects[id]]);
                }
                if(typeof callback == 'function') {
                    callback(found_objects[id]);
                }
            } else if(typeof callback == 'function') {
                callback(input.val());
            }
            var list_wrapper = $('.autocomplete-option-list-wrapper#options-for-'+id);
            list_wrapper.height(0);
            input.blur();
        }
        function getAutoCompleteParams(input) {
            var params = input.attr('data-ajax-autocomplete-params');
            var rows = params.split(',');
            var returnParams = {};
            $.each(rows, function(i, row) {
                var parts = row.split('=');
                returnParams[parts[0]] = parts[1];
            });
            return returnParams;
        }
        function resetAutoComplete(input, timeout) {
            if(timeout == undefined) {
                timeout = 250;
            }
            blur_options_timeout = setTimeout(function() {
                var clone = input.next();
                clone.removeAttr('placeholder');
                var id = input.parent().attr('id');
                var list_wrapper = $('.autocomplete-option-list-wrapper#options-for-'+id);
                list_wrapper.removeClass('shown');
                var ul = list_wrapper.find('ul');
                ul.empty();
                list_wrapper.height(0);
            },timeout);
        }
        function moveSelection(id, direction) {
            var list_wrapper = $('.autocomplete-option-list-wrapper#options-for-'+id);
            if(list_wrapper.length > 0) {
                var ul = list_wrapper.find('ul');
                if(ul.find('li').length > 0) {
                    var sort_of_selected = ul.find('li.sort_of_selected');
                    if(sort_of_selected.length == 0) {
                        sort_of_selected = ul.find('li:first');
                        sort_of_selected.addClass('sort_of_selected');
                    } else {
                        if(eval("sort_of_selected."+direction+"().length") > 0) {
                            sort_of_selected.removeClass('sort_of_selected');
                            sort_of_selected = eval("sort_of_selected."+direction+"()");
                            sort_of_selected.addClass('sort_of_selected');
                            found_objects[id] = found_complete[sort_of_selected.index()];
                        }
                    }
                }

            }
            return false;
        }
        function is_fixed(element) {
            var pos = element.css('position');
            if(pos == 'fixed') {
                return true;
            } else {
                if(element.prop('tagName') == 'BODY') {
                    return false;
                } else {
                    return is_fixed(element.parent());
                }
            }
        }
        return this;
    };
})( jQuery );