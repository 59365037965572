jQuery(document).ready(function($) {
    var check_for_complete = $('.check-for-complete-box');
    if(check_for_complete.length > 0) {
        check_for_complete.each(function() {
            var container = $(this);
            container.find(':input[required]').bind('keyup blur',function() {
                var show = true;
                container.find(':input[required]').not(':disabled').each(function() {
                    if($(this).val().length < 1) {
                        show = false;
                        return false;
                    }
                });
                //if(container.attr('id') == 'screen-2') {
                //    console.log(show);
                //}
                if(show === true) {
                    check_for_complete.find('.show-if-complete').addClass('show');
                } else {
                    check_for_complete.find('.show-if-complete').removeClass('show');
                }
            }).trigger('blur');
        });
    }
});