jQuery(document).ready(function($) {
    jQuery('.image-upload:not(.multiple)').dropzone({
        url: "/ajax/imageUpload",
        accept: function(file, done) {
            if (file.type != "image/jpeg" && file.type != "image/png") {
                this.removeAllFiles(true);
                var html = "Je kunt alleen jpg en png afbeeldingen uploaden.";
                show_modal('Sorry!',html,false,{
                    confirm: function(elm,data) {
                        elm.close();
                    }
                });
            }
            else { done(); }
        },
        maxfilesexceeded: function(file) {
            $('.image-upload').find('.dz-preview').not(':first').remove();
        },
        maxFiles:1,
        sending: function(file, xhr, data) {
            $('form').formLoader(true);
        },
        complete: function(file,data) {
            $('form').formLoader(false);
            var dropzone = this;

            //console.log(file.xhr.response);
            //console.log($('.image-upload').attr('data-upload-complete'));

            //Add save button / cancel button
            if($('.image-upload-wrapper').parent().children('.upload-buttons').length == 0) {
                $('.image-upload-wrapper').after('<div class="row upload-buttons">' +
                    '<div class="column small-12 medium-6">' +
                    '<button class="button secondary reset-dropzone">Wijzig</button>' +
                    '</div>' +
                    '<div class="column small-12 medium-6">' +
                    '<button class="button primary submit-dropzone">Bewaar</button>' +
                    '</div>' +
                    '</div>');

                var upload_buttons = $('.image-upload-wrapper').parent().children('.upload-buttons');
                upload_buttons.find('.reset-dropzone').click(function(e) {
                    e.preventDefault();
                    dropzone.removeAllFiles(true);
                });
                upload_buttons.find('.submit-dropzone').click(function(e) {
                    e.preventDefault();
                    var wrapper = $(this).parents('.upload-buttons').prev();
                    var submit_function = wrapper.attr('data-submit-method');
                    if(submit_function != undefined && typeof eval(submit_function) == 'function') {
                        eval(submit_function)();
                    }
                })
            }

            //<div class="bullseye-flash" id="arlSm" style="margin-top: 0px; opacity: 1;"><div class="row collapse"><div class="column small-12"><div class="callout alert"><button type="button" aria-label="Close alert" class="close-button"><span aria-hidden="true">×</span></button><span id="str">Het uploaden is mislukt. Waarschijnlijk is het bestand te groot.</span></div></div></div></div>

            //Remove buttons if response is "0", because then, the upload went horribly wrong
            if(file.xhr.response == "0") {
                $('.image-upload-wrapper').parent().children('.upload-buttons').remove();
                dropzone.removeAllFiles(true);
                bullseye_flash("Het uploaden is mislukt. Waarschijnlijk is het bestand te groot.","alert");
                return;
            }

            if($('.image-upload[data-upload-complete]').length > 0) {
                var fN = $('.image-upload').attr('data-upload-complete')+'_upload_complete';
                if(typeof fN == 'string' && typeof eval(fN) == 'function') {
                    eval(fN)(file,data);
                }
            } else {
                var form = $('.image-upload').parents('form:first');
                if(form.find('input[name=file_path]').length > 0) {
                    form.find('input[name=file_path]').val(file.xhr.response.toString().replace('_thumbnail',''));
                } else {
                    form.append('<input type="hidden" name="file_path" value="'+file.xhr.response.toString().replace('_thumbnail','')+'" />');
                }
            }

            var dz_image = $('.image-upload').find('.dz-image');
            var img = dz_image.find('img');
            dz_image.css({'background-image':'url(/'+file.xhr.response.toString()+')'});
            img.hide();
            var image = img.attr('src');
            getOrientation(file,function(pos){
                //var image = $('.image-upload').find('.dz-image img');
                if(pos > 1) {
                    dz_image.removeClass('orientation-2').removeClass('orientation-3').removeClass('orientation-4').removeClass('orientation-5').removeClass('orientation-6').removeClass('orientation-7').removeClass('orientation-8');
                    dz_image.addClass('orientation-'+pos);
                }
            });
        }
    });
    var multi = $('.image-upload.multiple');
    var maxFiles = 1;
    //var hiddenInit = false;
    if(multi.length > 0 ) {
        maxFiles = parseInt(multi.attr('data-max-files'));
        //var currentFiles = parseInt(multi.attr('data-current-files'));
        //if(currentFiles == parseInt(maxFiles)) {
        //    hiddenInit = true;
        //}
    }
    var uploaded_files = {};
    var myDropzone = false;
    multi.dropzone({
        url: "/ajax/imageUpload",
        init: function() {
            myDropzone = this;
        },
        accept: function (file, done) {
            if (file.type != "image/jpeg" && file.type != "image/png" && file.type != "application/pdf") {
                this.removeAllFiles(true);
                var html = "Je kunt alleen afbeeldingen uploaden waarvan de bestandsnaam eindigt op '<em>.jpg</em>', '<em>.png</em>' of '<em>.pdf</em>'.";
                show_modal('Sorry!', html, false, {
                    confirm: function (elm, data) {
                        elm.close();
                    }
                });
            } else {
                done();
            }
        },
        maxfilesreached: function (file) {
            $('.image-upload-wrapper').hide();
            return false;
        },
        maxfilesexceeded: function (file) {
            console.log('exceeded');
            $('.image-upload').find('.dz-preview').not(':first').remove();
        },
        maxFiles: parseInt(maxFiles),
        sending: function(file, xhr, data) {
            $('form').formLoader(true);
        },
        progress: function(progress){
            console.log(progress);
        },
        removedfile: function(file) {
            $('input#file-'+file.uid).remove();
        },
        complete: function(file,data) {
            if(myDropzone.options.maxFiles < 2) {
                myDropzone.options.maxfilesreached();
            }
            $('form').formLoader(false);
            $('.image-upload').find('.dz-preview').hide();

            var wrapper = $('.uploaded-picture-wrapper:first');
            if(wrapper.is(':visible')) {
                wrapper.clone(true,true).insertAfter($('.uploaded-picture-wrapper:last'));
                wrapper = $('.uploaded-picture-wrapper:last');
            } else {
                wrapper.show();
            }
            var uid = get_unique_id();
            wrapper.attr('id',uid);
            uploaded_files[uid] = file;
            var getSuffix = function(str) {
                return str.split('.')[str.split('.').length - 1];
            };
            var file_url = file.xhr.response.toString();
            if(getSuffix(file_url) != "pdf") {
                wrapper.find('.uploaded-picture').css({'background-image':'url("/'+file_url+'")'}).removeClass('fi-page-pdf');
            } else {
                wrapper.find('.uploaded-picture').addClass('fi-page-pdf');
            }
            wrapper.find('.uploaded-picture').bind('option-delete',function() {
                //Gets triggered by 'hover-options.js'
                var wrapper = $(this).parent();
                var thisFile = uploaded_files[wrapper.attr('id')];
                thisFile.previewElement = false;
                thisFile.uid = wrapper.attr('id');
                var rawElement = multi.get(0);
                rawElement.dropzone.removeFile(thisFile);
                //if(wrapper.index() > 0) {
                //    wrapper.remove();
                //} else {
                //    wrapper.hide();
                //}
                wrapper.hide();
                $('.image-upload-wrapper').show();
            });

            var form = wrapper.parents('form');
            form.append('<input type="hidden" name="file['+uid+']" id="file-'+uid+'" value="'+(file.xhr.response.toString().replace('_thumbnail', ''))+'" />')
        }
    });
    if(maxFiles < 1) {
        multi.parents('.image-upload-wrapper').hide();
        multi.bind('max-files-changed',function() {
            var max_files = $(this).attr('data-max-files');
            if(max_files > 0) {
                myDropzone.options.maxFiles = max_files;
                $(this).unbind('max-files-changed').parents('.image-upload-wrapper').show();
            }
        })
    }
    //if(hiddenInit === true) {
    //    multi.parents('.image-upload-wrapper').hide();
    //}
});
/*
 jQuery(document).ready(function($) {
 jQuery('.image-upload').dropzone({
 url: "/ajax/imageUpload",
 accept: function(file, done) {
 if (file.type != "image/jpeg") {
 this.removeAllFiles(true);
 var html = "Je kunt alleen afbeeldingen uploaden waarvan de bestandsnaam eindigt op '<em>.jpg</em>' of '<em>.jpeg</em>'.";
 show_modal('Sorry!',html,false,{
 confirm: function(elm,data) {
 elm.close();
 }
 });
 }
 else { done(); }
 },
 maxfilesexceeded: function(file) {
 $('.image-upload').find('.dz-preview').not(':first').remove();
 },
 maxFiles:1,
 complete: function(file,data) {
 var dropzone = this;
 //console.log(file.xhr.response);
 //console.log($('.image-upload').attr('data-upload-complete'));

 //Add save button / cancel button
 if($('.image-upload-wrapper').parent().children('.upload-buttons').length == 0) {
 $('.image-upload-wrapper').after('<div class="row upload-buttons">' +
 '<div class="column small-12 medium-6">' +
 '<button class="button secondary reset-dropzone">Wijzig</button>' +
 '</div>' +
 '<div class="column small-12 medium-6">' +
 '<button class="button primary submit-dropzone">Bewaar</button>' +
 '</div>' +
 '</div>');

 var upload_buttons = $('.image-upload-wrapper').parent().children('.upload-buttons');
 upload_buttons.find('.reset-dropzone').click(function(e) {
 e.preventDefault();
 dropzone.removeAllFiles(true);
 });
 upload_buttons.find('.submit-dropzone').click(function(e) {
 e.preventDefault();
 var wrapper = $(this).parents('.upload-buttons').prev();
 var submit_function = wrapper.attr('data-submit-method');
 if(submit_function != undefined && typeof eval(submit_function) == 'function') {
 eval(submit_function)();
 }
 })
 }

 if($('.image-upload[data-upload-complete]').length > 0) {
 var fN = $('.image-upload').attr('data-upload-complete')+'_upload_complete';
 if(typeof fN == 'string' && typeof eval(fN) == 'function') {
 eval(fN)(file,data);
 }
 } else {
 var form = $('.image-upload').parents('form:first');
 if(form.find('input[name=file_path]').length > 0) {
 form.find('input[name=file_path]').val(file.xhr.response.toString());
 } else {
 form.append('<input type="hidden" name="file_path" value="'+file.xhr.response+'" />');
 }
 }

 var dz_image = $('.image-upload').find('.dz-image');
 var img = dz_image.find('img');
 dz_image.css({'background-image':'url(/'+file.xhr.response.toString()+')'});
 img.hide();
 var image = img.attr('src');
 getOrientation(file,function(pos){
 //var image = $('.image-upload').find('.dz-image img');
 if(pos > 1) {
 img.removeClass('orientation-2').removeClass('orientation-3').removeClass('orientation-4').removeClass('orientation-5').removeClass('orientation-6').removeClass('orientation-7').removeClass('orientation-8');
 img.addClass('orientation-'+pos);
 }
 });
 }
 });

 //console.log( $._data($(window), 'events') );
 });
*/