jQuery(document).ready(function($) {
    var wait_for_hide = {};
    $('*[data-hover-options]').each(function() {
        var elm = $(this);
        var options = elm.attr('data-hover-options').split(',');
        var p = elm.parent();
        if(p.css('position') != 'relative') {
            p.css('position','relative');
        }
        elm.after('<div class="hover-options-wrapper"><div class="table"><div class="cell"></div></div></div>');
        var wrapper = p.find('.hover-options-wrapper');
        $.each(options,function(key,option) {
            wrapper.find('.cell').append('<a href="#" class="'+option+'"></a>');
        });
        wrapper.find('a').click(function(e) {
            e.preventDefault();
            var a = $(this);
            var classes = a.attr('class').split(' ');
            $.each(classes, function(key, value) {
                a.parents('.hover-options-wrapper').prev().trigger('option-'+value);
            });
        });
        elm.bind('click mouseenter',function() {
            var elm = $(this);
            var p = elm.parent();
            var wrapper = p.find('.hover-options-wrapper');
            if(wait_for_hide[wrapper.parent().index()]) {
                clearTimeout(wait_for_hide[wrapper.parent().index()]);
            }
            wrapper.css({'width':elm.outerWidth(),'height':elm.outerHeight(),'left':elm.position().left});
            wrapper.addClass('show').trigger('focus');
        }).mouseleave(function() {

            var wrapper = $(this).parent().find('.hover-options-wrapper');
            wait_for_hide[wrapper.parent().index()] = setTimeout(function() {
                wrapper.trigger('click');
            },500);
        });
        wrapper.bind('click mouseleave',function() {
            var elm = $(this);
            var p = elm.parent();
            var wrapper = p.find('.hover-options-wrapper');
            wrapper.removeClass('show');
        }).mouseenter(function() {
            var wrapper = $(this).parent().find('.hover-options-wrapper');
            if(wait_for_hide[wrapper.parent().index()]) {
                clearTimeout(wait_for_hide[wrapper.parent().index()]);
            }
        });
    });
});