function show_modal(title,html,str,options,new_location) {

    if($ == undefined) {
        var $ = jQuery.noConflict();
    }

    var unique_id = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 15; i++)
        unique_id += possible.charAt(Math.floor(Math.random() * possible.length));

    //var m = $('.reveal').clone();
    var m = $('.reveal');
    if(new_location != undefined) {
        new_location.append(m);
    } else {
        $('body').append(m);
    }


    m.prop('id',unique_id);

    //if(typeof html == 'object' && str==undefined && options==undefined) {
    //    m.find('h1').remove();
    //    m.find('p.lead').remove();
    //    options = html;
    //    html=title;
    //    m.html(html);
    //} else {
    //    if(title==undefined || title.length==0 || title==false) {
    //        m.find('h1').remove();
    //    } else {
    //        m.find('h1').html(title);
    //    }
    //    if(str==undefined || str.length==0 || str==false) {
    //        m.find('p.lead').remove();
    //    } else {
    //        m.find('p.lead').html(str);
    //    }
    //
    //    m.find('.html').html(html);
    //}
    if(title==undefined || title.length==0 || title==false) {
        m.find('h1').remove();
    } else {
        m.find('h1').html(title);
    }
    if(str==undefined || str.length==0 || str==false) {
        m.find('p.lead').remove();
    } else {
        m.find('p.lead').html(str);
    }

    m.find('.html').html(html);


    var elm = m;



    /*TEST!!!
    options.confirm(elm);
    return;
     */


    var popup = new Foundation.Reveal(elm);
    popup.open();

    //elm.foundation('reveal', 'open');

    if(options != undefined && options.opened != undefined && typeof options.opened == 'function') {
        $(document).on('opened.fndtn.reveal', '[data-reveal]', function () {
            options.opened(elm);
        });
    }

    if(elm.find('.cancel-button').length>0) {
        elm.find('.cancel-button').click(function(e){
            e.preventDefault();
            popup.close();
        });
    }
    if(elm.find('form').length>0) {
        elm.find('form').submit(function(e){
            e.preventDefault();
            data = modal_get_form_data($(this));
            confirm_this_modal(popup,options,data);
        });
    } else if(elm.find('.confirm-button').length>0) {
        elm.find('.confirm-button').click(function(e){
            e.preventDefault();
            confirm_this_modal(popup,options);
        });
    }
}

function confirm_this_modal(popup,options,data)
{
    if(options.confirm != undefined) {
        if(typeof options.confirm == 'function') {
            options.confirm(popup,data);
        } else {
            location.href = options.confirm;
        }
    } else {
        popup.close();
    }
}

function delete_modal(element,options)
{
    var html = '<a href="#" class="confirm-button button alert no-margin">'+ucfirst(Lang.get('global.yes'))+'</a> <a href="#" class="button secondary no-margin cancel-button">'+ucfirst(Lang.get('global.cancel'))+'</a>';
    show_modal(Lang.get('global.confirm_delete'),html,Lang.get('global.delete_message',{'description':element}),options);
}

function confirm_modal(options)
{
    var html = '<a href="#" class="confirm-button button no-margin">'+ucfirst(Lang.get('global.yes'))+'</a> <a href="#" class="button secondary no-margin cancel-button">'+ucfirst(Lang.get('global.cancel'))+'</a>';
    var msg = Lang.get('global.continue_message');
    if(options.message) {
        msg = options.message;
    }
    show_modal(Lang.get('global.confirm'),html,msg,options);
}

function show_ajax_modal(view, options,data)
{
    var $ = jQuery.noConflict();
    $.iGet("view/admin.ajax."+view,data,function(data){
        var html = $.parseHTML(data);
        show_modal(html,options);
    });

}

function modal_get_form_data(form)
{
    var $ = jQuery.noConflict();
    var paramObj = {};
    $.each(form.serializeArray(), function(_, kv) {
        if (paramObj.hasOwnProperty(kv.name)) {
            paramObj[kv.name] = $.makeArray(paramObj[kv.name]);
            paramObj[kv.name].push(kv.value);
        }
        else {
            paramObj[kv.name] = kv.value;
        }
    });
    return paramObj;
}