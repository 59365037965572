(function( $ ){
    $.fn.formLoader = function(direct) {
        if(direct == undefined) {
            var loadertimeout = false;
            this.unbind('submit.formLoader').bind('submit.formLoader',function() {
                start_loading();
            });
        } else {
            if(direct === true) {
                start_loading();
            }
            if(direct === false) {
                stop_loading();
            }
        }
        function start_loading() {

            if($('.form-loader-overlay').length == 0) {
                $('body').append("<div class='form-loader-overlay'></div>");
            }
            if(loadertimeout !== false) {
                clearTimeout(loadertimeout);
            }
            $('.form-loader-overlay').addClass('shown');
            $( document ).unbind('ajaxComplete.formLoader').bind('ajaxComplete.formLoader',function() {
                stop_loading();
            });
        }
        function stop_loading() {
            $('.form-loader-overlay').removeClass('shown');
            loadertimeout = setTimeout(function() {
                $('.form-loader-overlay:not(.shown)').remove();
            },500);
        }
        return this;
    };
})( jQuery );
jQuery(document).ready(function($) {
    $('form').formLoader();
});
