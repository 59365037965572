jQuery(document).ready(function($) {
    var googleCityAjax;
    $('[data-google-city-search]').each(function() {
        loadGoogleCitySearch($(this));
    });
    $(document).bind('ajaxComplete.googleCitySearchLoader', function() {
        setTimeout(function() {
            $('[data-google-city-search]').each(function() {
                loadGoogleCitySearch($(this));
            });
        },250);
    });
    function loadGoogleCitySearch(elm) {

        if(!elm.parent().hasClass('google-city-search-wrapper')) {
            var id = get_unique_id();

            elm.after('<div class="google-city-search-wrapper" id="'+id+'"></div>');
            var wrapper = $('.google-city-search-wrapper#'+id);
            elm.focus(function() {
                var form = $(this).parents('form');
                if(form.length == 0) {
                    elm.blur();
                } else {
                    var now_focus = false;
                    form.find(':input').each(function() {
                        if(now_focus === false) {
                            if($(this).is(':focus')) {
                                now_focus = true;
                            }
                        } else {
                            if($(this).attr('type') != 'hidden') {
                                $(this).focus();
                                return false;
                            }
                        }
                    });
                }
            });
            elm.attr('autocomplete','off');
            var clone = elm.clone().removeAttr('data-google-city-search');

            var lat_name = new_input_name(elm.attr('name'),'lat');
            var lng_name = new_input_name(elm.attr('name'),'lng');
            wrapper.html(elm);
            wrapper.prepend(clone);
            wrapper.find('input[type=text]:last').removeAttr('name').removeAttr('id').removeAttr('required');

            wrapper.append('<input type="hidden" name="'+lat_name+'" /><input type="hidden" name="'+lng_name+'" />');

            wrapper.find('input[type=text]:first').bind('keyup.googleCitySearch',function(e) {
                var i = $(this);
                var s = wrapper.find('input[type=text]:last');
                if(e.keyCode != 13 && e.keyCode != 9) {
                    var val = i.val();
                    googleCityAjax = $.ajax({
                        'url': '/ajax/helper/google_city_search/',
                        'data': {args:{'action': 'search_for_cities', 's':val}},
                        'type': 'GET',
                        'success': function(data) {
                            data = JSON.parse(data);
                            if(data != null && data.status == "OK") {
                                //console.log(data.predictions);
                                var first_city_object = data.predictions[0];
                                var city_name = first_city_object.terms[0].value;

                                if(first_city_object.matched_substrings[0].offset > 0) {
                                    i.addClass('error');
                                } else {
                                    if(i.hasClass('error')) {
                                        i.removeClass('error');
                                    }

                                    var part = city_name.substr(first_city_object.matched_substrings[0].length);
                                    s.attr('placeholder',val+part);
                                    i.attr('data-place-id',first_city_object.place_id);
                                }
                            } else {
                                i.addClass('error');
                            }
                        }
                    });
                }
            }).bind('keydown.googleCitySearch blur.googleCitySearch', function(e) {
                var i = $(this);
                var s = wrapper.find('input[type=text]:last');
                var latInput = wrapper.find('input:nth-child(3)');
                var lngInput = wrapper.find('input:nth-child(4)');
                if(e.type != 'blur' && (e.keyCode != 13 && e.keyCode != 9)) {
                    s.removeAttr('value');
                    s.removeAttr('placeholder');
                    i.removeAttr('data-place-id');
                    latInput.removeAttr('value');
                    lngInput.removeAttr('value');
                    if(googleCityAjax != undefined) {
                        googleCityAjax.abort();
                    }
                } else {
                    if(e.type != 'blur' && e.keyCode != 9) {
                        e.preventDefault();
                    }
                    var get_details = function() {
                        if(i.attr('data-place-id') != undefined) {
                            var place_id = i.attr('data-place-id');
                            var name = i.attr('name');
                            googleCityAjax = $.ajax({
                                'url': '/ajax/helper/google_city_search/',
                                'data': {args:{'action': 'get_place_coordinates', 'place_id':place_id}},
                                'type': 'GET',
                                'success': function(data) {
                                    data = JSON.parse(data);
                                    if(data != null && data.status == "OK") {
                                        latInput.val(data.result.geometry.location.lat);
                                        lngInput.val(data.result.geometry.location.lng);
                                        i.val(data.result.name);
                                        s.removeAttr('placeholder');
                                        //i.blur();
                                        if(i.hasClass('error')) {
                                            i.removeClass('error');
                                        }

                                    } else {

                                        if(data.status == "OVER_QUERY_LIMIT") {
                                            i.attr('data-google-error',data.status);
                                        }
                                        i.addClass('error');
                                    }
                                }
                            });
                        } else {
                            i.addClass('error');
                        }
                    };

                    if(googleCityAjax != undefined && googleCityAjax.readyState == 1) {
                        //It's still loading, so wait for it
                        $(document).bind('ajaxComplete.googleCitySearch', function() {
                            $(this).unbind('ajaxComplete.googleCitySearch');
                            setTimeout(function() {
                                get_details();
                            },250);
                        });
                    } else {
                        get_details();
                    }
                }
            });
            if(wrapper.find('input[type=text]:first').val().length > 0) {
                wrapper.find('input[type=text]:first').trigger('keyup');
                setTimeout(function() {
                    wrapper.find('input[type=text]:first').trigger('blur');
                },true);
            }
        }
    }
});