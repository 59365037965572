(function( $ ){
    $.iJax = function(direction,data,callback,type) {
        if(type == undefined) {
            type = 'GET';
        }
        if(typeof data == 'function') {
            callback = data;
            data = false;
        }

        if(typeof callback == 'function') {
            callback = {'success':callback};
        }

        var url = "/ajax/"+direction;
        $.ajax({
            url:url,
            data:data,
            type:type,
            success:function(data) {
                if(callback != undefined && callback.success != undefined && typeof callback.success == 'function') {
                    callback.success(data);
                }
            },
            error:function(xhr,txt,err) {
                //bullseye_flash(xhr.responseText,'alert');
                if(callback != undefined && callback.error != undefined && typeof callback.error == 'function') {
                    callback.error(xhr,txt,err);
                }
            }
        });
        return this;
    };
})( jQuery );

(function( $ ){
    $.iGet = function(direction,data,callback) {
        $.iJax(direction,data,callback,'GET');
        return this;
    };
})( jQuery );

(function( $ ){
    $.iGetModel = function(model,action,data,callback) {
        var direction = "model/"+model+"/"+action;
        $.iGet(direction,data,callback);
        return this;
    };
})( jQuery );

(function( $ ){
    $.iSearchModel = function(model,field,value,callback) {
        $.iGetModel(model,'search',{'field':field,'value':value},callback);
        return this;
    };
})( jQuery );

(function( $ ){
    $.iPost = function(direction,data,callback) {
        $.iJax(direction,data,callback,'POST');
        return this;
    };
})( jQuery );

(function( $ ){
    $.iPostModel = function(model,action,data,callback) {
        var sent_data = data;
        var direction = "model/"+model+"/"+action;

        if(typeof callback == 'function') {
            callback = {'success':callback};
        }

        var new_callback = {
            'success':function(data)
            {
                if($('body').hasClass('admin')) {
                    model_flash(model, action, data, sent_data);
                }
                if(typeof callback.success == 'function') {
                    callback.success(data);
                }
            },
            'error':function(xhr,txt,code)
            {
                if(typeof callback.error == 'function') {
                    callback.error(xhr,txt,code);
                }
            }
        };

        $.iPost(direction,data,new_callback);
        return this;
    };
})( jQuery );

//function ucfirst(string) {
//    return string.charAt(0).toUpperCase() + string.slice(1);
//}
//function model_flash(model, action, data, sent_data) {
//    var attr = Lang.get('global.item');
//    model = model.toLowerCase();
//    action = action.toLowerCase();
//    if(Lang.has('global.'+model)) {
//        attr = Lang.get('global.'+model);
//    }
//    attr = ucfirst(attr);
//
//    var str = "";
//    if(Lang.has('messages.'+action+'_with_name') && data!=undefined && data.name!=undefined &! (action == "attach" || action == "detach")) {
//        str = Lang.get('messages.'+action+'_with_name',{'attribute':attr,'name':data.name});
//    } else {
//        if(action == "attach" || action == "detach") {
//
//            var child_model = sent_data.field.substr(0,sent_data.field.length-1).toLowerCase();
//            var child_attr = Lang.get('global.item');
//            if(Lang.has('global.'+child_model)) {
//                child_attr = Lang.get('global.'+child_model);
//            }
//            child_attr = ucfirst(child_attr);
//            if(data.name==undefined) {
//                str = Lang.get('messages.'+action,{'child':child_attr,'parent':attr.toLowerCase()});
//            } else {
//                str = Lang.get('messages.'+action+'_with_name',{'child':child_attr,'parent':attr.toLowerCase(),'name':data.name});
//            }
//
//        } else {
//            str = Lang.get('messages.'+action,{'attribute':attr});
//        }
//    }
//
//    bullseye_flash(str);
//}
//function bullseye_flash(str,type) {
//    var $ = jQuery.noConflict();
//    //Types can be: radius, success, alert, secondary
//    if(type == undefined) {
//        type = "success";
//    }
//
//    var offset = 0;
//    if($('.bullseye-flash').length>0) {
//        $('.bullseye-flash').each(function(){
//            offset = offset+$(this).height();
//        });
//    }
//
//    var id = bullseye_makeid();
//    var html = '<div class="bullseye-flash" id="'+id+'"><div class="row collapse"><div class="column small-12"><div class="alert-box '+type+'" data-alert=""><span id="str"></span><a class="close" href="">×</a></div></div></div></div>';
//    $('body').prepend(html);
//    var f = $('.bullseye-flash#'+id);
//    f.find('span#str').html(str);
//    f.find('a.close').click(function(e){
//        e.preventDefault();
//        bullseye_closeFlash(f);
//    });
//    f.css({'margin-top': f.height()*-1}).animate({'opacity':1,'margin-top':offset},function(){
//        var wait_to_remove = setTimeout(function(){
//            bullseye_closeFlash(f);
//        },3000);
//        if(str.length > 200) {
//            clearTimeout(wait_to_remove);
//        }
//    });
//}
//function bullseye_closeFlash(f) {
//    var $ = jQuery.noConflict();
//    $('.bullseye-flash').each(function(){
//        $(this).animate({'margin-top':parseInt($(this).css('margin-top')) - f.height()},function(){
//            if($(this).attr('id') == f.attr('id')) {
//                $(this).remove();
//            }
//        })
//    });
//}
//function bullseye_makeid()
//{
//    var $ = jQuery.noConflict();
//    var text = "";
//    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//
//    for( var i=0; i < 5; i++ )
//        text += possible.charAt(Math.floor(Math.random() * possible.length));
//
//    if($('#'+text).length>0) {
//        return bullseye_makeid();
//    }
//    return text;
//}