jQuery(document).ready(function($) {
    var elms = $('*[data-input-type]');
    if(elms.length > 0) {
        var found_elms = {};
        elms.each(function() {
            $(this).trigger('custom-change');
            var name = $(this).attr('data-input-name');
            var type = $(this).attr('data-input-type');

            var attributes = $(this)[0].attributes;
            var attributes_object = {};
            for(var i in attributes) {
                var attr = attributes[i].nodeName;
                if(attr != undefined && attr.split('data-input-').length > 1) {
                    attributes_object[attr.split('data-input-')[1]] = attributes[i].nodeValue;
                }
            }
            var attr_string = '';
            for(var key in attributes_object) {
                attr_string = attr_string+" "+key+"=\""+attributes_object[key]+"\"";
            }


            if(type == 'radio') {
                $(this).addClass("no-choice");
                var value = $(this).attr('data-input-value');
                //It's an array
                var arr = [];
                if(found_elms[name]) {
                    arr = found_elms[name];
                }
                var input = $(get_input_name(name, value));
                if(input.length < 1) {
                    $('<input '+attr_string+' style="display:none;">').insertAfter($(this));
                }
                $(this).click(function() {
                    var name = $(this).attr('data-input-name');
                    $("*[data-input-name="+name+"]").removeClass('no-choice');
                    var value = $(this).attr('data-input-value');
                    var input = $(get_input_name(name, value));

                    $(':input[name='+name+']').removeAttr('checked');
                    input.prop('checked',true);

                    $('*[data-input-name='+name+']').removeAttr('data-input-checked');
                    $(this).attr('data-input-checked','checked');
                    input.trigger('change');
                });
            }
            //found_elms[name]
        });
    }
    function get_input_name(name, value) {
        if(value == undefined) {
            return ':input[name='+name+']';
        } else {
            return ':input[name='+name+'][value='+value+']';
        }
    }
});