(function( $ ){
    var xhr = false;
    var wait = false;
    $.fn.postalCodeChecker = function() {
        this.each(function() {
            var elm = $(this);
            if(!elm.hasClass('checks-postalcodes')) {

                //Find the number input by looping $.parent() until you find a number input in it, or the parent == body, then die
                var number_input = find_matching_element(elm,'input#street_no');


                if(number_input === false) {
                    console.log("Could not find input#street_no in any parent of input[name="+elm.attr('name')+"].\nPostal codes won't be checked.");
                } else {
                    elm.keyup(function() {
                        var postal_code = $(this).val().trim().replace(' ','').toUpperCase();
                        var address_no = find_matching_element($(this),'input#street_no').val().trim();
                        if(postal_code.length == 6 && !isNaN(address_no) && address_no.length > 0) {
                            check_data(postal_code, address_no, $(this));
                        }
                    });
                    elm.addClass('checks-postalcodes');
                    number_input.keyup(function() {
                        find_matching_element($(this),'input.check-this-postalcode').trigger('keyup');
                    });
                }
            }
        });
        return this;

        function check_data(postal_code, address_no, sender) {
            if(xhr !== false) {
                xhr.abort();
            }
            clearTimeout(wait);
            wait = setTimeout(function() {
                var street_input = find_matching_element(sender,"input#street");
                var city_input = find_matching_element(sender,"input#city");
                street_input.val('').attr('disabled','disabled');
                city_input.val('').attr('disabled','disabled');
                xhr = $.ajax({
                    url:'/ajax/helper/get_address_by_postalcode',
                    data: {
                        args: {
                            'postalcode': postal_code,
                            'number': address_no
                        }
                    },
                    success:function(data) {
                        street_input.removeAttr('disabled');
                        city_input.removeAttr('disabled');
                        if(data.id) {
                            street_input.val(data.street).trigger('keyup');
                            city_input.val(data.city.label).trigger('keyup');
                        } else {
                            console.log("Sum-ting-went-wong -.-");
                            console.log(data);
                        }
                    }
                })
            },500);
        }
        function find_matching_element(elm, find_str) {
            if(elm.prop('tagName') == 'BODY') {
                return false;
            }
            var p = elm.parent();
            if(p.find(find_str).length > 0) {
                return p.find(find_str);
            } else {
                return find_matching_element(p, find_str);
            }

        }
    };
})( jQuery );
jQuery(document).ready(function($) {
    $('input.check-this-postalcode').postalCodeChecker();
});