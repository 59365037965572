jQuery(document).ready(function() {
    reload_textarea_max_size();
});
function reload_textarea_max_size() {
    var $ = jQuery.noConflict();

    if($('textarea:not([max-textarea-size-loaded=1])').length > 0) {
        $('textarea:not([max-textarea-size-loaded=1])').each(function() {
            if($(this).attr('maxlength') > 0) {
                var textArea = $(this);
                textArea.attr('max-textarea-size-loaded',1);
                var max = $(this).attr('maxlength');
                textArea.css({'resize':'none','padding-bottom':'30px'});
                var p = textArea.parent();
                var c = p;
                if(p.css('position') != 'relative') {
                    var h = textArea.outerHeight();
                    var w = textArea.outerWidth();
                    var id = get_unique_id();
                    textArea.before('<div id="'+id+'"></div>');
                    c = $('div#'+id);
                    c.css('position', 'relative');
                    c.html(textArea);
                    textArea = c.find('textarea');
                }
                c.append('<div class="max-textarea-size-wrapper"><span class="count">0</span> / <span class="max-count">'+max+'</span></div>');
                var wrapper = c.find('.max-textarea-size-wrapper');
                wrapper.css({
                    'text-align':'right',
                    'font-size':'11px',
                    'position':'absolute',
                    'bottom':0,
                    'left':0,
                    'width':'100%',
                    'padding-right':'10px',
                    'padding-bottom':'10px',
                });

                textArea.bind('keyup keydown',function() {
                    var size = $(this).val().length;
                    var span = $(this).next('.max-textarea-size-wrapper').find('span.count');
                    span.html(size);
                    var max = parseInt($(this).attr('maxlength'));
                    if(size >= max-(max/5)) {
                        span.css('color','red');
                    } else {
                        span.css('color','inherit');
                    }
                }).trigger('keyup');
            }
        });
    }
}